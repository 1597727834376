// fonts weight
	$thin: 100;
	$ultralight: 200;
	$light: 300;
	$normal: 400;
	$medium: 500;
	$sbold: 600;
	$bold: 700;
	$xbold: 800;
	$black: 900;

	$fontResMax: 2100;
	// $fontResMax: 900;
	$fontResMin: 320;
// fonts weight

//font family
	/* @font-face {
		font-family: 'icomoon';
		src:  url('fonts/icomoon.eot?zmssf');
		src:  url('fonts/icomoon.eot?zmssf#iefix') format('embedded-opentype'),
			url('fonts/icomoon.ttf?zmssf') format('truetype'),
			url('fonts/icomoon.woff?zmssf') format('woff'),
			url('fonts/icomoon.svg?zmssf#icomoon') format('svg');
		font-weight: normal;
		font-style: normal;
	} */
//font family


// font var
	$ico: 'icomoon';
	$mainFont: 'Montserrat', sans-serif;
	$subFont: 'Montserrat', sans-serif;
// font var
