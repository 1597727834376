// content
header {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	padding: 0;
	margin: 0;
	background: var(--color-background-primary);
	text-transform: none;
	/*	z-index: 999999999;*/
	z-index: 1001;
	transition-property: top;
	transition-duration: .3s;

	@media screen and (max-width: 1024px) {
		z-index: 999; //lower than main body modal
	}

	> .wrap {
		/*overflow: visible;

		@include lg {*/
		/* overflow: hidden; */
		/*}*/
	}

	.col {
		position: static;
	}

	.logo {
		width: auto;
		padding: 0;
		padding: 14px 10px;
		transition-property: padding;
		transition-duration: .3s;
		transition-delay: 0s;

		@include lg {
			padding: 24px 10px;
		}

		a {
			display: block;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0;
		}

		img {
			display: block;
			float: left;
			max-width: 100%;
			width: 30px;
			height: 36px;
			/* width: 38px;height: 45px; */
			margin: 0;
			padding: 0;
			transition-property: width, height;
			transition-duration: .3s;
			transition-delay: 0s;
		}
	}

	.topBar {
		padding: 13px .5rem;
		background: var(--color-background-primary-dark-1);

		p, a {
			margin: 0;
			padding: 0;
			font-size: 8px;
			color: $w;
			white-space: nowrap;
		}

		a {
			&.btn {
				padding: 4px 15px;
				margin-right: 10px;
				text-transform: uppercase;

				&-primary {
					&:hover {
						border-color: $blue2;
						background: $blue2;
					}
				}
			}
		}

		.back {
			position: relative;
			display: inline-block;
			padding-right: 18px;
			font-size: 14px;

			a {
				font-size: inherit;
				text-decoration: none;

				&:hover {
					color: $blue2;
				}
			}

			.icon {
				vertical-align: middle;
			}

			&:after {
				content: '';
				position: absolute;
				top: -10px;
				bottom: -10px;
				left: 23px;
				display: inline-block;
				width: 1px;
				height: 28px;
				margin: auto;
				padding: 0;
				background: rgba($w,.2);
			}

			@include lg {
				display: none;
			}
		}
	}

	.searchTop:not(.lpDesign) {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
		min-width: 240px;
		height: auto;
		width: 100%;
		padding: 10px 0;
		background: $blue;
		margin-top: -112px;

		@include sm {
			/* right: -120px;left: -120px; */
			left: 0;
			right: 0;
			width: auto;
		}

		@include lg {
			background: none;
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			display: block;
			padding: 0 .5rem;
			margin-top: 0;
		}

		form {
			width: 100%;
			margin: 0;
			padding: 0;

			.row {
				> .col {
					margin: 0;

					&:first-of-type {
						padding-right: 0;
					}

					&:last-of-type {
						padding-left: 0;
					}
				}
			}

			input,
			button {
				height: 28px;
				background: $gl;
				color: $gd;
				line-height: 18px;
			}

			input {
				font-size: 12px;
				@include br(18px 0 0 18px);
			}

			input,
			button {
				&[type="submit"] {
					@include br(0 18px 18px 0);

					.icon {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.chat-label {
		.count {
			position: absolute;
			top: 2px;
			display: inline-block;
			width: 15px;
			height: 15px;
			font-size: 9px;
			line-height: 15px;
			text-align: center;
			background: $green;
			@include br(100%);

			&:empty {
				display: none;
			}
		}
	}

	.profile {
		padding: 10px 0;

		@include lg {
			padding: 0 .5rem;
		}

		.icon {
			display: inline-block;
			font-size: 25px;
			padding: 0 5px;
		}

		a {
			position: relative;
			display: inline-block;
			padding: 0;
			margin: 0;
			color: $w;
			text-decoration: none;

			&:hover {
				color: $blue2;
			}
		}

		.live,
		.mobileSearch,
		.chat {
			@include lg {
				display: none;
			}
		}

		.live {
			position: relative;

			.icon-play {
				position: absolute;
				top: -6px;
				right: 3px;
				display: block;
				width: 15px;
				height: 15px;
				margin: 0;
				padding: 0;
				font-size: 8px;
				color: $w;
				line-height: 15px;
				text-align: center;
				background: $red;
				@include br(100%);
			}
		}

		.bell {
			.count {
				position: absolute;
				top: 2px;
				right: 2px;
				display: inline-block;
				width: 15px;
				height: 15px;
				font-size: 9px;
				line-height: 15px;
				text-align: center;
				background: $green;
				@include br(100%);

				&:empty {
					display: none;
				}
			}

			&:hover {
				.count {
					background: $w;
					color: var(--color-text-secondary);
				}
			}
		}

		.user {
			position: relative;
			display: none;
			padding: 0;
			padding-right: 5px;

			@include lg {
				display: inline-block;
			}

			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				display: inline-block;
				width: 6px;
				height: 6px;
				margin: auto;
				content: '\e91a';
				font-family: $ico;
				font-size: 6px;
				line-height: 1;
				transform: rotate(0deg);
				transition-property: transform;
				transition-duration: .3s;
			}

			&.open {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		&Menu {
			/* display: block;float: left; width: 100%; */
			/* position: absolute;top: 100%;right: 0;
				display: none; */
			display: block;
			float: left;
			max-width: 100%;
			height: auto; /* max-height: calc(100vh - 114px); */
			padding: 15px 60px;
			background: $w;
			color: var(--color-text-secondary);
			font-size: 12px;
			font-weight: $bold;
			max-height: calc(100vh - 110px);
			overflow-y: auto;
			/* @include br(22px 0 0 22px); */
			@include lg {
				position: absolute;
				top: 100%;
				right: 0;
				display: none;
				width: auto;
				min-width: 176px;
				max-width: 200px;
				padding: 15px 5px;
				/* text-align: right; */
				text-align: center;
				@include br(22px);
				will-change: filter;
				filter: drop-shadow(0 0 10px rgba($b,.3));
			}

			a {
				display: block;
				float: left;
				width: 100%;
				color: var(--color-text-secondary);
				text-align: left;

				&.btn {
					padding: 4px 15px;
					text-align: center;
					color: $w;
				}
			}

			ul {
				display: block;
				float: left;
				width: 100%;
				margin: 0;
				padding: 0;
				border: none;
				list-style-type: none;

				li {
					padding: 5px 10px;
					display: block;
					float: left;
					width: 100%;

					a {
						padding: 4px 10px;
						background: none;
					}
				}

				&.open {
					display: block;
				}
			}

			&Box {
				display: inline-block;
				float: none;
				width: calc(100% - 20px);
				margin: 0 10px;
				padding: 12px;
				background: #f8f8f8;
				@include br(12px);

				.icon {
					display: inline-block;
					width: auto;
					height: auto;
					margin: 0;
					padding: 0;
					margin-right: 5px;
					font-size: 14px;

					&-logo {
						width: 25px;
						height: 25px;
						padding: 5px;
						color: $w;
						background: #e8e8e8;
						@include br(6px);
					}
				}

				ul {
					padding: 0;

					li {
						padding: 5px;

						a {
							padding: 0;

							.icon {
								padding: 0;

								&-logo {
									padding: 5px;
								}
							}
						}
					}
				}
			}
		}
	}

	.lang {
		display: block;
		float: left;
		width: 100%;
		margin: 0;
		padding: 10px 20px;
		pointer-events: auto;

		@include lg {
			padding: 10px 20px;
		}

		ul {
			position: relative;
			display: block;
			float: left;
			width: auto;
			height: 26px;
			width: 100%;
			margin: 0;
			padding: 0;
			list-style: none;
			z-index: 9;

			li {
				display: inline-block;
				float: left;
				width: 100%;
				margin: 0;
				padding: 5px 0;
				background: $w;
				clear: both;
				display: none;

				a {
					position: relative;
					display: flex;
					float: left;
					justify-content: flex-start;
					align-items: center;
					padding: 5px;
					margin: 0;
					color: var(--color-text-secondary);
					text-decoration: none;
					text-transform: none;
					border: none;
					cursor: pointer;
					white-space: nowrap;

					@media screen and (min-width: $lg) {
						padding: 0;
					}

					.icon {
						display: none;
						font-size: 8px;
					}

					img {
						display: inline-block;
						float: left;
						width: 25px;
						height: auto;
						margin: 0;
						padding: 0;
						margin-right: 6px;
					}

					&:hover {
						color: $blue2;
						text-decoration: none;
					}
				}

				&.active {
					display: block;

					a {
						.icon {
							display: inline-block;
						}

						&:hover {
							color: $blue2;
						}
					}
				}
			}

			&.open {
				li {
					a {
					}
				}
			}
		}
	}

	.fixedMobileBack {
		display: none;
		position: relative;
		padding: 0 5px;
		font-size: 13px;
		color: $w;

		&:after {
			content: '';
			position: absolute;
			top: -10px;
			bottom: -10px;
			right: 0px;
			display: inline-block;
			width: 1px;
			height: 28px;
			margin: auto;
			padding: 0;
			background: rgba($w,.2);
		}

		a {
			display: block;
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 5px;
			text-decoration: none;
		}
	}

	&.fixed {
		position: fixed;
		/*top: -48px;*/
		z-index: 1001;

		@media screen and (max-width: 1024px) {
			z-index: 999; //lower than main body modal
		}

		.topBar {
			display: none;
		}

		.logo {
			padding: 5px 7px;
			transition-delay: .3s;

			@include lg {
				padding: 10px;
			}

			img {
				width: 25px;
				height: 29px;
				transition-delay: .3s;
			}
		}

		.fixedMobileBack {
			display: block;

			@include lg {
				display: none;
			}
		}
	}
}
header.fixed>.lpDesign {
	top: 48px;
}
// content

body.fixedHeader main.authenticated {
    padding-top: 112px;
}

@include lg {
    body.fixedHeader main.authenticated {
        padding-top: 131px;
    }
}