// menu
.submenu {
	position: relative;
	top: 0;
	left: auto;
	display: block;
	float: left;
	width: 100%;
	max-width: 100%;
	margin: 0;
	border: none;
	background: rgba($w,0);
	transition-property: background, top;
	transition-duration: .3s;
	z-index: 1;
	font-size: 14px;
	font-weight: $bold;
	line-height: 18px;

	@include lg {
		font-size: 12px;
	}

	.wrap {
		padding: 0 8px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		/* float: left;width: 100%; */
		float: none;
		width: auto;
		max-width: 100%;
		margin: 0 auto;
		padding: 10px 0;
		list-style: none;

		@include lg {
			justify-content: center;
			overflow-x: auto;
		}

		li {
			display: block;
			float: left;
			width: auto;
			height: auto;
			max-width: 100%;
			flex: 0 1 auto;
			margin: 0;
			padding: 3px 4px;

			@include sm {
				max-width: 50%;
			}

			@include md {
				max-width: 25%;
				padding: 5px 3px;
			}

			a {
				display: block;
				float: left;
				width: 100%;
				margin: 0;
				padding: 4px 8px;
				color: var(--color-text-secondary);
				text-decoration: none;
				text-align: center;
				white-space: nowrap;
				border: none;
				background: #ececec;
				@include br(50px);
				transition-property: color, background, border;

				@include lg {
					padding: 5px 10px;
				}

				&.active {
					background: $blue;
					color: $w;
				}

				&:hover {
					background: $blue;
					color: $w;
				}

				.icon {
					color: inherit;

					&-live_scoring {
						span {
							&:after,
							&:before {
								color: inherit;
							}
						}
					}
				}
			}

			&.live,
			&.bluel,
			&.sportcam,
			&.yellow {
				a {
					.icon {
						display: inline-block;
						font-size: 10px;
						line-height: 18px;
						vertical-align: middle;
					}
				}
			}

			&.live,
			&.bluel {
				a {
					background: $bluel;
					color: $w;

					&:hover {
						background: $blue;
					}
				}
			}

			&.sportcam,
			&.yellow {
				a {
					background: $yellow;
					color: $w;

					&:hover {
						background: $blue2;
					}
				}
			}

			&.active {
				a {
					background: $blue;
					color: $w;
				}
			}
		}
	}

	.close {
		display: none;
		text-decoration: none;
		color: var(--color-text-secondary);
		text-shadow: none;
		opacity: 1;

		@include lg {
			display: block;
		}

		.icon-plus {
			display: inline-block;
			float: left;
			transform: rotate(45deg);
		}

		&:hover {
			color: $blue2;
		}
	}

	&Nav {
		display: none;
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0;

		.icon {
			position: absolute;
			top: 0;
			left: auto;
			right: auto;
			bottom: 0;
			display: inline-block;
			width: 18px;
			height: 18px;
			margin: 0;
			padding: 0;
			font-size: 10px;
			line-height: 18px;
			text-align: center;
			cursor: pointer;

			&:hover {
				color: $blue2;
			}

			&.next {
				right: 0;
				transform: rotate(-90deg) translateX(50%);
			}

			&.prev {
				transform: rotate(90deg) translateX(-50%);
				left: 0;
			}
		}
	}

	&.fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 47px;
		padding: 0;
		background: rgba($w,1);
		z-index: 999999999;

		& + * {
			padding-top: 60px;
		}

		@include lg {
			background: none;
		}

		.submenuNav {
			display: block;

			@include lg {
				display: none;
			}
		}

		ul {
			flex-wrap: nowrap;
			justify-content: flex-start;
			overflow-x: scroll;
			padding: 6px 0;

			@include lg {
				flex-wrap: wrap;
				justify-content: center;
				overflow-x: auto;
			}

			li {
				a {
					padding: 1px 6px;
					font-size: 10px;
				}
			}
		}
	}

	.nav-pills .nav-link.active {
		background-color: #2f3f7c;
	}
}
// menu end