/* bootstrap extend */
    /* max-width */
        @for $i from 1 through 20 {
            .mw-#{$i * 5} {max-width: #{$i * 5%};}
        }
    /* max-width */

    .jcc {
        justify-content: center;
        &-sm {@media screen and (min-width: $sm) {justify-content: center;}}
        &-md {@media screen and (min-width: $md) {justify-content: center;}}
        &-lg {@media screen and (min-width: $lg) {justify-content: center;}}
    }
    .jce {
        justify-content: flex-end;
        &-sm {@media screen and (min-width: $sm) {justify-content: flex-end;}}
        &-md {@media screen and (min-width: $md) {justify-content: flex-end;}}
        &-lg {@media screen and (min-width: $lg) {justify-content: flex-end;}}
    }
    .jcs {
        justify-content: flex-start;
        &-sm {@media screen and (min-width: $sm) {justify-content: flex-start;}}
        &-md {@media screen and (min-width: $md) {justify-content: flex-start;}}
        &-lg {@media screen and (min-width: $lg) {justify-content: flex-start;}}
    }
    .jcb {
        justify-content: space-between;
        &-sm {@media screen and (min-width: $sm) {justify-content: space-between;}}
        &-md {@media screen and (min-width: $md) {justify-content: space-between;}}
        &-lg {@media screen and (min-width: $lg) {justify-content: space-between;}}
    }
    .jca {
        justify-content: space-around;
        &-sm {@media screen and (min-width: $sm) {justify-content: space-around;}}
        &-md {@media screen and (min-width: $md) {justify-content: space-around;}}
        &-lg {@media screen and (min-width: $lg) {justify-content: space-around;}}
    }
    .aic {
        align-items: center;
        &-sm {@media screen and (min-width: $sm) {align-items: center;}}
        &-md {@media screen and (min-width: $md) {align-items: center;}}
        &-lg {@media screen and (min-width: $lg) {align-items: center;}}
    }
    .ais {
        align-items: flex-start;
        &-sm {@media screen and (min-width: $sm) {align-items: flex-start;}}
        &-md {@media screen and (min-width: $md) {align-items: flex-start;}}
        &-lg {@media screen and (min-width: $lg) {align-items: flex-start;}}
    }
    .aie {
        align-items: flex-end;
        &-sm {@media screen and (min-width: $sm) {align-items: flex-end;}}
        &-md {@media screen and (min-width: $md) {align-items: flex-end;}}
        &-lg {@media screen and (min-width: $lg) {align-items: flex-end;}}
    }
    .aist {
        align-items: stretch;
        &-sm {@media screen and (min-width: $sm) {align-items: stretch;}}
        &-md {@media screen and (min-width: $md) {align-items: stretch;}}
        &-lg {@media screen and (min-width: $lg) {align-items: stretch;}}
    }
    .aibl {
        align-items: baseline;
        &-sm {@media screen and (min-width: $sm) {align-items: baseline;}}
        &-md {@media screen and (min-width: $md) {align-items: baseline;}}
        &-lg {@media screen and (min-width: $lg) {align-items: baseline;}}
    }
/* bootstrap extend */
