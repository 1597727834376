// content
	.search {
		&Categories {
			padding: 20px 0;
			width: 100%;
			ul {
				list-style: none;
				padding: 0;margin: 0;
				text-align: center;
				@include lg {
					text-align: left;
				}
				li {
					display: inline-block;width: auto;height: auto;
					margin: 0;padding: 0 3px;
					a {
						display: inline-block;width: 100%;
						margin: 0;padding: 8px 15px;margin-bottom: 5px;
						font-size: 13px;font-weight: $bold;
						background: $g;
						@include br(50px);
						text-decoration: none;
						&.active,
						&:hover {
							background: $blue;
							color: $w;
						}
					}
				}
			}
			.close {
				text-decoration: none;
				color: $b;
				text-shadow: none;
				opacity: 1;
				.icon-plus {
					display: inline-block;
					transform: rotate(45deg);
				}
				&:hover {
					color: $blue2;
				}
			}
		}
		&Results {
			width: 100%;
			margin: 0;padding: 0;
			&Single {
				padding: 13px 20px;
				margin-bottom: 6px;
				background: #f8f8f8;
				@include br(50px);
				a {
					text-decoration: none;
				}
				.img {
					width: cs(70,40);flex-basis: cs(70,40);max-width: cs(70,40);
					padding: 0;
					@include lg {
						padding: 0 5px;
					}
					a {
						display: block;float: left;width: 100%;
						margin: 0;padding: 0;
						text-align: center;
						img {
							display: block;float: none;width: auto;max-width: 100%;
							margin: 0 auto;padding: 0;
						}
						&.user {
							overflow: hidden;
							@include br(100%);
						}
					}
				}
				.cont {
					padding: 0 15px;
					.flag {
						max-width: 50px;
						padding-left: 0;
						a {
							display: block;float: left;width: 100%;
							margin: 0;padding: 0;
							img {
								display: block;float: left;width: 100%;
								margin: 0;padding: 0;
							}
						}
					}
					.name {
						padding: 0;
					}
					.info {
						font-size: 11px;
						padding-left: 0;
						a {
							text-decoration: none;
						}
						p {
							margin: 0;
						}
					}
					.buttons {
						padding-top: 10px;
						padding-left: 0;
						a {
							margin: 2px;
						}
						.close {
							margin: 0;
							display: inline-block;float: none;
							color: $blue;line-height: 1;
							text-shadow: none;
							text-decoration: none;
							opacity: 1;
							vertical-align: middle;
							.icon-plus {
								display: inline-block;float: left;
								transform: rotate(45deg);
							}
							&:hover {
								color: $blue2;
							}
						}
					}
				}
			}
		}
	}
// content

#search-results-app .modal-dialog {
	max-width: 600px;
}

.searchResultsSingle .close {
	padding: 0;
}

.close-modal:focus {
	outline: none;
}

.searchResultsSingle .close span {
	font-size: 1.5rem;
	position: static !important;
}

.searchResults p {
	margin-bottom: 1rem;
}

.searchResults p strong {
	font-size: 14px;
	line-height: 1.4;
}

.searchResults .flag {
	line-height: 1.4;
	font-size: 14px;
	font-weight: 400;
}

.submenu-search {
	width: 95%;
}

.searchResults .flag-icon {
	line-height: inherit;
	width: 29px;
}

.searchResults .name {
	line-height: 1.4;
	font-size: 14px;
	font-weight: 400;
}

.searchResults div.info {
	font-weight: 400;
}

.searchResults div.info i {
	padding-left: 5px;
}

.search-magnifying-glass {
	border-radius: 0 18px 18px 0;
}

.search-input-field {
	border-radius: 18px 0 0 18px;
}

.fixed-search {
	z-index: 99999;
}

.secondary-search {
	background-color: white;
}

.secondary-search .icon-plus:before {
	display: inline-block;
	transform: rotate(45deg);
}
