// content
	#browserSupport {
		position: relative;
		width: 100%;max-width: 100%;
		padding: 10px 0;

		color: $w;font-weight: $bold;
		text-align: center;

		background: $orange;
		overflow-y: auto;
		p {
			margin: 0;padding: 0;
		}
	}
// content
