// content
footer {
	margin: 0;
	padding: 30px 18px;
	background: var(--color-background-primary);
	color: $w;

	@include md {
		padding: 30px 0px;
	}

	@include lg {
		padding: 60px 0;
	}

	.wrap {
		> .row {
			@include lg {
				padding: 0 30px;
			}
		}
	}

	h3 {
		display: none;
		margin-bottom: 25px;
		font-size: 16px;
		font-weight: $xbold;

		@include md {
			display: block;
			font-size: 21px;
		}
	}

	h4 {
		display: block;
		margin-bottom: 25px;
		font-size: 16px;

		.icon {
			font-size: 14px;
		}

		@include md {
			display: none;
		}
	}

	.collapse:not(.show),
	.collapse {
		@include md {
			display: block;
		}
	}

	a {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 20px;
		line-height: 21px;

		li {
			margin-bottom: 5px;

			@include lg {
				margin-bottom: 0;
			}
		}
	}

	a, p {
		line-height: 21px;
	}

	.support {
		margin-bottom: 20px;

		@include lg {
			margin-bottom: 55px;
		}

		a {
			display: inline-block;
			text-decoration: none;
		}
	}

	.social {
		margin-bottom: 20px;

		@include lg {
			margin-bottom: 30px;
		}

		a {
			display: inline-block;
			margin: 0 .5rem;
			text-decoration: none;
			font-size: 25px;
		}
	}

	.copy {
		display: inline-block;
		width: auto;
		margin-bottom: 20px;
		font-size: 8px;

		@include lg {
			width: 100%;
			font-size: 10px;
		}

		p {
			line-height: 1.2;
		}
	}
}
// content
