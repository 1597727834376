// content
	.team {		
		@include lg {			
		}
		h2 {
			margin-bottom: 10px;
		}
		&Slider {
			&Single {
				padding: 25px;
				font-size: 10px;
			}
			img {
				display: block;float: left;width: 100%;max-width: 100%;
				margin: 0;padding: 0;margin-bottom: 23px;
				border-radius: 100%;
			}
			.name {
				font-size: 16px;
				margin: 0;
			}
		}
	}
// content
