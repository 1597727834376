 // content
.contact {
	.contactForm {
		.caldera-grid {
			form {
				display: block;
				float: left;
				width: 100%;
				margin: 0;
				padding: 0;
			}

			div {
				display: block;
				float: left;
				width: 100%;
				height: auto;
				margin: 0;
				padding: 0;

				&.form-control,
				&.selectize-input {
					padding: 14px 20px;
				}

				&.selectize-dropdown,
				&.selectize-control {
					padding: 0;
				}
			}

			[class^="col-"],
			[class*=" col-"] {
				padding: 0 .5rem;
				margin-bottom: 30px;
			}

			.form-control {
				height: auto;
			}

			.selectize-dropdown .optgroup-header,
			.selectize-dropdown [data-selectable] {
				padding: 8px 8px;
			}

			label {
				font-family: $mainFont;
				font-size: 15px;
				line-height: 1.1;
				color: $b;

				span {
					color: $red;
				}
			}

			button,
			input,
			select,
			textarea {
				-webkit-appearance: none;
				-moz-appearance: none;
				outline: none;
				display: block;
				width: 100%;
				height: auto;
				padding: 14px 20px;
				margin: 0;
				font-family: $mainFont;
				font-size: 16px;
				line-height: 20px;
				color: $gd;
				font-style: italic;
				background: $w;
				border: 1px solid $b;
				/* vertical-align: bottom; */
				/* filter: drop-shadow(0 0 19px rgba($b, .1)); */
				@include br(5px);

				&[placeholder] {
					color: $gd;
				}

				&::-webkit-input-placeholder {
					color: $gd;
					opacity: 1;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: $gd;
				}

				&::-moz-placeholder { /* Firefox 19+ */
					color: $gd;
				}

				&:-ms-input-placeholder {
					color: $gd;
				}

				&[type="submit"] {
					cursor: pointer;
					float: left;
					width: auto;
					margin: 0 auto;
					padding: 6px 16px;
					background: $b;
					font-size: 13px;
					color: $w;
					line-height: 20px;
					font-weight: $normal;
					font-style: normal;
					text-decoration: none;
					border: 1px solid $b;
					cursor: pointer;
					transition-property: background, color, border-color, transform, filter;
					transition-duration: .3s;

					@include br(5px);

					.icon {
						display: inline-block;
						margin: 0 5px;
						position: relative;
						transition: transform .2s;
						/* font-size: 6px; */
						line-height: 1;
						/* transform: rotate(-90deg); */
					}

					&:hover {
						/* background: $b; */
						/* border-color: $b; */
						color: $b;
						text-decoration: none;

						.icon {
							transform: scale(1.2);
							// transform: translateX(50vw);
						}
					}
				}

				&[type="radio"],
				&[type="checkbox"] {
					// display: none;
					position: absolute;
					top: 1px;
					left: 10px;
					display: block;
					width: auto;
					height: auto;
					float: left;
					appearance: checkbox;
					margin: 0;
					padding: 0;

					& + label {
						display: block;
						padding-left: 20px;
						cursor: pointer;
					}
					// + label {
					// 	// font-family: $lato;
					// 	padding-left: 25px;
					// 	line-height: 20px;
					// 	position: relative;
					// 	display: inline-block;
					// 	cursor: pointer;
					// 	&:before {
					// 		font-size: 14px;
					// 		line-height: 20px;
					// 		text-align: center;
					// 		content: '';
					// 		position: absolute;
					// 		top: 0;
					// 		left: 0;
					// 		width: 20px;
					// 		height: 20px;
					// 		background: $w;
					// 		@include br(3px);
					// 		border: 1px solid $b;
					// 	}
					// }
					// &:checked {
					// 	+ label {
					// 		&:before {
					// 			// content: "e903";
					// 			content: "✓";
					// 			color: $green;
					// 		}
					// 	}
					// }
				}

				&[type="file"] {
					padding-left: 0;
					padding-right: 0;
					border: none;
				}

				&:active,
				&:focus {
					color: $b;
					// border-color: $b;
					&[placeholder] {
						color: $b;
					}

					&::-webkit-input-placeholder {
						color: $b;
						opacity: 1;
					}

					&:-moz-placeholder { /* Firefox 18- */
						color: $b;
					}

					&::-moz-placeholder { /* Firefox 19+ */
						color: $b;
					}

					&:-ms-input-placeholder {
						color: $b;
					}
				}
			}

			select {
				padding-right: 30px;
				cursor: pointer;
				/* background: rgb(225,235,245);
					background: -moz-linear-gradient(top, rgba(225,235,245,1) 0%, rgba(255,255,255,1) 100%);
					background: -webkit-linear-gradient(top, rgba(225,235,245,1) 0%,rgba(255,255,255,1) 100%);
					background: linear-gradient(to bottom, rgba(225,235,245,1) 0%,rgba(255,255,255,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1ebf5', endColorstr='#ffffff',GradientType=0 ); */
				/* box-shadow: inset 0px 30px 40px -20px rgba($b, .3); 
				background-image: url(./../img/downB.svg);*/
				background-position: center right 10px;
				background-repeat: no-repeat;
				background-size: auto 10px;
			}

			textarea {
				// padding-top: 18px;
				height: 200px;
				// line-height: 1.2;
			}

			.checkbox {
				position: relative;
				display: block;
				float: left;
				width: 100%;
				margin: 0;
				padding: 0;
				padding-left: 30px;

				label {
					display: block;
					float: left;
					width: 100%;
					font-size: 10px;
					line-height: 16px;
					padding: 0;
				}

				input {
					&[type="checkbox"] {
						top: 5px;
						left: 5px;
					}
				}
			}

			.error {
				input,
				select,
				button,
				textarea {
					border-color: $red;
				}
			}
		}
	}
}
// content
