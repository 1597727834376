// brakepoints
	/* $max: 100em;
	$xlg: 88.75em;
	$xl: $xlg;
	$lg: 75em;
	$md: 64em;
	$sm: 48em;
	$xs2: 30em;
	$xs: (320px - 16px); */


	/* $max: 1600px;
	$xlg: 1420px;
	$xl: $xlg;
	$lg: 1200px;
	$md: 1024px;
	$sm: 768px;
	$xs2: 480px;
	$xs: (320px - 16px); */

	$max: 1600px;
	$xlg: 1400px;
	$xl: 1200px;
	$lg: 1024px;
	$md: 768px;
	/* $sm: 480px; */
	$sm: 576px;
	$sms: 420px;
	$xs2: 320px;
	$xs: 0;

	$grid-breakpoints: (
		xs: 0,
		sm: 480px,
		md: 768px,
		lg: 1024px
	);

	$container-max-widths: (
		sm: 420px,
		md: 720px,
		lg: 960px
	);


	.col-5-1 {
		flex: 0 0 auto;
		flex-basis: 20%;
		max-width: 20%;
	}
	@include sm {
		.col-sm-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}
	@include md {
		.col-md-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}
	@include lg {
		.col-lg-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}
	@include xl {
		.col-xl-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}
	@include xlg {
		.col-xlg-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}
	@include max {
		.col-max-5-1 {
			flex: 0 0 auto;
			flex-basis: 20%;
			max-width: 20%;
		}
	}

// brakepoints
