// content
.clear {
	position: relative;
	padding: 15px 0;

	.img {
		text-align: center;
	}

	img {
		width: auto;
		height: auto;
		max-width: 100%;
		margin: 0 auto;
		padding: 0;
		margin-bottom: 30px;

		&.fullWindow {
			width: 100vw;
			max-width: 100vw;
			margin-left: -20%;
			margin-right: -20%;
		}

		&.size-full {
			display: block;
			float: left;
			width: 100%;
		}

		&.alignright {
			float: right;
			width: 100%;

			@media screen and (min-width: $sm) {
				width: auto;
				margin-left: 20px;
			}
		}

		&.alignleft {
			float: left;
			width: 100%;

			@media screen and (min-width: $sm) {
				width: auto;
				margin-right: 20px;
			}
		}
	}

	&.regulations {
		font-size: 11px;

		h4, h5 {
			font-size: 13px;
			margin-bottom: .5rem;
		}

		p {
			margin-bottom: 30px;
		}
	}

	&Top {
		padding: 30px 0 10px;

		@include lg {
			padding: 30px 0;
		}

		h1, h2, h3 {
			margin: 0;

			a {
				display: inline-block;
				width: auto;
				margin: 0;
				padding: 0;
				text-decoration: none;
			}
		}

		h1, h2 {
			font-size: 25px;
		}

		h3 {
			font-size: 16px;
		}

		form {
			font-size: 8px;

			> .row {
				> .col {
					padding: 0;

					&.filter {
						padding: 0 5px;
						flex-basis: 50%;

						@include md {
							flex-basis: 25%;
						}

						@include lg {
							flex-basis: 0;
						}
					}
				}
			}

			label {
				padding-left: 20px;
			}

			.filter {
				> a {
					position: relative;
					-webkit-appearance: none;
					-moz-appearance: none;
					outline: none;
					display: block;
					width: 100%;
					padding: 3px 1rem;
					margin: 0;
					padding-right: 30px;
					color: var(--color-text-secondary);
					font-family: $mainFont;
					font-size: 11px;
					line-height: 1.125;
					font-weight: $bold;
					text-transform: none;
					text-decoration: none;
					background: $w;
					border: 2px solid $blue;
					box-shadow: none;
					cursor: pointer;
					@include br(50px);

					&:after {
						content: "\e91a";
						position: absolute;
						top: 0;
						bottom: 0;
						right: 15px;
						display: block;
						width: 10px;
						height: 10px;
						margin: auto;
						font-family: $ico;
						font-size: 5px;
						line-height: 10px;
						color: $b;
						text-align: center;
						border: none;
						background: none;
						transform-origin: center center;
						transition-property: transform;
						transition-duration: .3s;
					}

					&[aria-expanded="true"],
					&.active {
						background: $blue;
						color: $w;

						&:after {
							transform: rotate(180deg);
							color: $w;
						}
					}
				}

				.dropdown {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					/* display: block!important; */
					max-width: 80%;
					margin: auto;
					padding: 15px 0 10px;
					background: $g;
					border: none;
					overflow: hidden;
					will-change: filter;
					filter: drop-shadow(0 0 18px rgba($b,.25));
					@include br( 0 0 12px 12px);
					z-index: 99;

					label {
						display: inline-block;
						padding: 0;
						padding-right: 5px;
						font-size: 10px;
						font-weight: $sbold;
						text-align: right;
					}

					input {
						@include br(50px);

						&[readonly] {
							background: $w;
						}
					}
				}
			}
		}

		a {
			&.settings {
				display: inline-block;
				width: 28px;
				height: 28px;
				margin: 0 3px;
				padding: 0;
				color: $w;
				font-size: 18px;
				line-height: 30px;
				text-align: center;
				text-decoration: none;
				vertical-align: text-top;
				background: $orange;

				@include br(6px);

				&:hover {
					background: $blue2;
				}
			}
		}

		.share {
			a {
				text-decoration: none;

				.icon {
					display: inline-block;
					padding: 0 2px;
					font-size: 12px;
					vertical-align: middle;
				}
			}
		}

		.date {
			display: inline-block;
			width: 100%;
			padding: 0 15px;
			margin: 0;
			margin-bottom: 20px;
			font-size: 16px;
			font-weight: $sbold;
		}

		.buttons {
			display: inline-block;
			width: 100%;
			padding: 0 15px;
			margin: 0;
			margin-bottom: 20px;
		}
	}

	&Cont {
		font-weight: $sbold;
		overflow-x: auto;

		table {
			/* width: 100%;min-width: 420px; */
			width: auto;
			margin-bottom: 10px;
			overflow: visible;

			tr {
				td,
				th {
					position: relative;

					&:first-of-type {
						text-align: center;
					}

					&:last-of-type {
						text-align: center;
					}

					&:last-of-type {
						.opt {
							&Menu {
								right: -12px;
								transform: translateX(0);

								&:before {
									left: auto;
									right: 12px;
								}
							}
						}
					}

					img {
						display: inline-block; /* width: 14px;height: 11px; */
						padding: 0;
						margin: 0;
						vertical-align: middle;
					}

					span {
						display: inline-block;
						padding: 0 5px;
						vertical-align: middle;
					}

					a {
						display: inline-block;

						&.btn {
							font-size: 9px;
							padding-left: 15px;
							padding-right: 15px;
							margin: 0;
						}

						&.fav {
							font-size: 16px;
							line-height: 1;
							color: #dedede;

							&:hover {
								color: $bluel;
							}

							&.active {
								color: $red;
							}
						}
					}

					.result {
						display: inline-block;
						padding: 2px 10px;
						background: $blue;
						color: $w;
						font-size: 9px;
						@include br(4rem);
					}

					.filter {
						display: inline-block;
						width: 19px;
						height: 19px;
						color: var(--color-text-secondary);
						text-align: center;
						vertical-align: middle;
						border: 2px solid $blue;
						@include br(7px);

						.icon {
							padding: 0;
							margin: 0;
							font-size: 10px;
						}
					}

					.remove {
						font-size: 14px;
					}

					.buttons {
						display: inline-block;
						float: left;
						width: 100%;
						margin: 0;
						padding: 0;
						padding-left: 20px;
						clear: both;
						font-size: 6px;
						text-align: left;
						white-space: nowrap;

						a {
							&.btn {
								padding: 0px 5px;
							}
						}
					}

					.rankingChange {
						display: inline-block;
						float: none;
						white-space: nowrap;

						.icon {
							display: inline-block;
							vertical-align: middle;
							padding: 0 2px;
						}

						&.inc {
							.icon {
								color: $green;
								transform: rotate(-90deg);
							}
						}

						&.dec {
							.icon {
								color: $red;
								transform: rotate(90deg);
							}
						}
					}

					.opt {
						position: relative;

						a {
							display: inline-block;
							transition-property: color, background, opacity;

							&:hover {
								color: var(--color-text-secondary);
								opacity: .5;
							}
						}

						&Menu {
							position: absolute;
							top: calc(100% + 14px);
							right: 0px;
							display: none;
							float: left;
							width: 110px;
							margin: 0 auto;
							padding: 13px;
							list-style: none;
							background: $w;
							transform: translateX( calc(50% - 7px) );
							will-change: filter;
							filter: drop-shadow(0 0 10px rgba($b,.3));

							@include br(14px);
							z-index: 9;

							&.open {
								display: block;
							}

							li {
								a {
									display: block;
									float: left;
									width: 100%;
									margin: 0;
									margin-bottom: 4px;
									padding: 8px 5px 6px;
									font-size: 9px;
									font-weight: $bold;
									white-space: nowrap;
									background: $g;
									text-decoration: none;

									@include br(4rem);

									&:hover {
										background: $bluel !important;
										color: $w;
									}
								}
							}

							&:before {
								content: '';
								position: absolute;
								bottom: 100%;
								left: 0;
								right: 0;
								display: block;
								width: 16px;
								margin: 0 auto;
								padding: 0;
								background: none;
								border-bottom: 14px solid $w;
								border-left: 8px solid transparent;
								border-right: 8px solid transparent;
							}
						}
					}
				}
			}
		}

		.no-rankings {
			font-weight: 400;

			img {
				display: block;
				margin-top: 3em;
				margin-bottom: 3em;
			}

			a {
				margin-bottom: 3em;
			}

			p {
				margin-bottom: 3em;
				font-weight: normal;
			}
		}
	}
}
// content
