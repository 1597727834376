// modules
@import 'modules/all';
// modules

// main
html,
body {
    position: relative;
    width: 100%;
    height: auto;
    font-family: $mainFont;
    font-size: 14px;
    line-height: 1.4;
    font-weight: $normal;
    color: var(--color-text-secondary);
    background-color: var(--color-background-secondary);
    overflow-x: hidden;
    scroll-behavior: smooth;

    &.i-amphtml-scroll-disabled {
        header {
            z-index: 0;
        }
    }

    @media print {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        size: A4 landscape;
    }
}

body {
    overflow: hidden;   
    transition-property: padding;
    transition-duration: .3s;   

    &.modal-open {
        height: 100vh;
        overflow: hidden;
    }

}

* {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

.row {
    margin: 0;

    &.nowrap {
        flex-wrap: nowrap;
    }
}

.nowrap {
    &:not(.row) {
        white-space: nowrap;
    }
}

.flex {
    display: flex;
    flex-wrap: wrap;

    &.nowrap {
        flex-wrap: nowrap;

        &-sm {
            @include sm {
                flex-wrap: nowrap;
            }
        }

        &-md {
            @include md {
                flex-wrap: nowrap;
            }
        }

        &-lg {
            @include lg {
                flex-wrap: nowrap;
            }
        }

        &-xlg {
            @include xlg {
                flex-wrap: nowrap;
            }
        }

        &-max {
            @include max {
                flex-wrap: nowrap;
            }
        }
    }
}

.wrap {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;

    &.visible {
        overflow: visible;
    }

    @media screen and (min-width: $lg + 16px) {
        width: $lg;
    }

    @media screen and (min-width: $xl + 16px) {
        width: $xl;
    }
    /* @media screen and (min-width: $xlg + 16px) { width: $xlg; } */
    &.wide {
        @media screen and (min-width: $max + 16px) {
            width: 100%;
            padding: 0 6rem;
        }
    }
    /* @media screen and (min-width: $md + 16px) { width: $md; } */
}

.ws-nowrap {
    white-space: nowrap;
}

header,
main,
footer {
    float: left;
    width: 100%;
    height: auto;

    section {
        float: left;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
    }
}

h1, h2, h3, h4, h5 {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    font-family: $mainFont;
    font-weight: $bold;
}

h5 {
    font-size: 18px;
}

strong {
    font-weight: $bold;

    &.xbold {
        font-weight: $xbold;
    }
}

em,
i {
    font-style: italic;
}

a, button {
    border: 0;
    color: inherit;
    transition-property: color;
    transition-duration: .3s;

    &:hover {
        color: $blue2;
    }
}

table {
    width: auto;
    max-width: 100%;
    margin: 0;
    border: none;
    vertical-align: middle;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 2px;

    tr {
        border: none;

        td.darker, th.darker {
            background: $tableDarkerBg;
        }

        td, th {
            /* padding: 4px 10px; */
            padding: 4px cs(10,6);
            font-size: 12px;
            vertical-align: middle;
            text-align: center;
            /* background: $g; */
            background: $tableBg;
            border: none;
            border-left-color: $gl;
            border-right-color: $gl;
            border-bottom: 3px solid $gl;

            @include lg {
                padding: 6px 10px;
            }

            &:first-of-type {
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                /* text-align: left; */
                padding-left: 20px;
                /* padding-left: cs(20,15); */
                @include lg {
                    padding-left: 20px;
                }
            }

            &:last-of-type {
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                padding-right: 20px;
                /* padding-right: cs(20,15); */
                @include lg {
                    padding-right: 20px;
                }
            }

            span {
                white-space: nowrap;
                vertical-align: middle;
                padding: 0 5px;

                &:not(.row) {
                    display: inline-block;
                }

                &.row {
                    padding: 0;
                }

                &.col {
                    padding: 0 5px;
                }

                &.label {
                    margin: 1px;
                    padding: 0px 3px;
                    font-size: 10px;
                    color: $w;
                    font-weight: $sbold;
                    text-transform: none;
                    background: $blue;
                    @include br(50px);

                    &.label {
                        &-g {
                            background: $g;
                            color: var(--color-text-secondary);
                        }

                        &-b {
                            background: $b;
                            color: $w;
                        }

                        &-primary {
                            background: $bluel;
                        }

                        &-success {
                            background: $green;
                        }

                        &-info {
                            background: $blue2;
                        }

                        &-warning {
                            background: $orange;
                        }

                        &-danger {
                            background: $red;
                        }

                        &-light {
                            background: $w;
                            color: var(--color-text-secondary);
                        }
                    }

                    &Result {
                        padding: 2px 10px;
                        font-size: 10px;
                        font-weight: $bold;
                    }
                }
            }

            p {
                margin: 0;
            }

            a {
                text-decoration: none;
                white-space: nowrap;

                @include lg {
                    white-space: normal;
                }

                &.btn {
                    display: inline-block;
                    margin: 1px;
                    border: none;
                }
            }

            .flag {
                display: inline-block;
                float: none;
                width: 14px;
                height: 11px;
                margin-right: 5px;
                vertical-align: middle;

                img {
                    display: inline-block;
                    float: left;
                    width: 14px;
                    height: 11px;
                    padding: 0;
                    margin: 0;
                }
            }

            .icon {
                display: inline-block;
                padding: 0 3px;
                font-size: inherit;
                vertical-align: middle;

                &.fz20 {
                    font-size: 20px;
                }
            }

            &.ws-nowrap {
                white-space: normal;

                @include lg {
                    white-space: nowrap;
                }
            }
        }

        th {
            background: none;
        }
    }

    &.firstLeft {
        tr {
            td, th {
                /* &:first-of-type {text-align: left!important;} */
            }

            > :first-child {
                text-align: left !important;
            }
        }
    }

    &.secondLeft {
        tr {
            td, th {
                /* &:nth-of-type(2) {text-align: left!important;} */
            }

            > :nth-child(2) {
                text-align: left !important;
            }
        }
    }

    &.thirdLeft {
        tr {
            td, th {
                /* &:nth-of-type(2), */
                /* &:nth-of-type(3) {text-align: left!important;} */
            }

            > :nth-child(3) {
                text-align: left !important;
            }
        }
    }

    &.fullWidth {
        width: 100% !important;
    }

    &.onWhite {
        tr {
            td, th {
                border-color: $w;
            }

            td {
                background: $tableOnWhiteBg;
            }
        }
    }

    &.clear, &.clearLpDesgin {
        border: none;
        vertical-align: top;

        tr {
            td, th {
                border: none;
                background: none;
                vertical-align: top;
            }

            th {
                /* text-align: right; */
            }
        }
    }

    &.condensed {
        tr {
            td, th {
                padding: 2px 7px;

                @include lg {
                    padding: 5px 15px;
                }
            }
        }
    }
}

.oVisible {
    overflow: visible !important;
}

.mb-24 {
    margin-bottom: 24px;
}

.animate {
    opacity: 0;
    animation-play-state: paused;

    &.animated {
        opacity: 1;
        animation-duration: 1.5s;
        animation-delay: .5s;
        animation-play-state: running;
    }
}

.mobile {
    display: inline-block;
}

.tablet {
    display: none;
}

.desktop {
    display: none;
}

@include md {
    .mobile {
        display: none;
    }

    .tablet {
        display: inline-block;
    }

    .desktop {
        display: none;
    }
}

@include lg {
    .mobile {
        display: none;
    }

    .tablet {
        display: none;
    }

    .desktop {
        display: inline-block;
    }
}

.btn {
    padding: 7px 15px;
    font-size: 10px;
    color: $w;
    font-weight: $bold;
    text-transform: none;
    background: $blue;
    @include br(50px);

    @include lg {
        padding: 10px 30px;
    }

    &.btn {
        &-g {
            border-color: $g;
            background: $g;
            color: var(--color-text-secondary);
        }

        &-primary {
            border-color: $bluel;
            background: $bluel;

            &:hover {
                border-color: $blue;
                background: $blue;
            }
        }

        &-success {
            border-color: $green;
            background: $green;
        }

        &-info {
            border-color: $blue2;
            background: $blue2;
        }

        &-warning {
            border-color: $orange;
            background: $orange;
        }

        &-danger {
            border-color: $red;
            background: $red;
        }

        &-light {
            border-color: $blue;
            background: $w;
            color: var(--color-text-secondary);

            &:hover {
                color: $w;
            }
        }

        &-b {
            background: $b;
            border-color: $b;
            color: $w;
        }

        &-g {
            background: $g;
            border-color: $g;
            color: var(--color-text-secondary);
        }

        &-sm {
            padding: 2px 15px;

            @include lg {
                padding: 2px 30px;
            }
        }

        &-dropdown {
            background: none;
            color: var(--color-text-secondary);
            border: 2px solid $blue;
        }

        &.info {
            padding-left: 10px;
            padding-right: 10px;

            .icon {
                display: inline-block;
                width: 10px;
                height: 10px;
                color: var(--color-text-secondary);
                line-height: 1;
                background: $w;
                vertical-align: middle;
                @include br(100%);
            }
        }

        &Profile {
            padding: 0 3px;
            font-size: 7px;
            font-weight: $sbold;
        }
    }

    &:hover {
        background: $bluel;
        border-color: $bluel;
        color: $w;
    }

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
}

.bg {
    &-primary {
        border-color: $blue;
        background: $blue !important;
        color: $w;
    }

    &-success {
        border-color: $green;
        background: $green !important;
    }

    &-info {
        border-color: $blue2;
        background: $blue2 !important;
    }

    &-warning {
        border-color: $orange;
        background: $orange !important;
        color: $w;
    }

    &-danger {
        border-color: $red;
        background: $red !important;
    }

    &-light {
        border-color: $blue;
        background: $w !important;
    }
}

.lightbox {
    .lb-nav {
        a {
            &.lb-next,
            &.lb-prev {
                @include op(1);
            }
        }
    }

    .lb-data {
        .lb-number {
            display: none !important;
        }
    }
}

ol,
ul {
    li {
        &.disabled {
            opacity: .5;
            text-decoration: line-through;
            cursor: not-allowed;
        }
    }

    &.checkLpDesign {
        li {
            &:before {
                color: $blue_lpDesign;
            }
        }
    }

    &.check {
        list-style: none;
        padding-left: 26px;

        li {
            position: relative;

            &:before {
                content: "\e93b";
                position: absolute;
                top: 1px;
                right: calc(100% + 10px);
                display: inline-block;
                font-family: $ico;
                color: var(--color-text-secondary);
                font-size: 14px;
            }
        }

        &-green {
            li {
                &:before {
                    color: $green;
                }
            }
        }
    }
}

.nav-pills .nav-link.active {
    background-color: #2f3f7c;
}
// main

// partials
@import 'partials/all';
// partials


.dropdown-menu {
    max-height: 300px;
    overflow-y: auto !important;
}

.min-width-100 {
    min-width: 100px;
}

.alert {
    border-radius: 2rem;
    font-size: 12px;
    text-align: center;
}

.alert-primary {
    color: #2f3f7c;
    border-color: #d5d9e5;
    background: #d5d9e5;
}

input {
    color: #4a4a4a !important;
    font-weight: 400 !important;
}

#modal-enter-results input {
    border: none;
}

#modal-enter-results input.required {
    border-width: 1px;
    border-style: solid;
    border-color: $red!important;
}

.fixedHeader .rin-page-navbar-visible #landingPageContainer {
    padding-top: 3.5rem;
}