// menu
	.datepicker {
		.datepicker-days {
			table {
				tbody {
					tr {
						td {
							width: 20px;height: 20px;
							padding: 5px;
							font-size: 11px;font-weight: $sbold;
							text-align: center;
							@include br(0);
							&:first-of-type {
								@include br(50px 0 0 50px);
							}
							&:last-of-type {
								@include br(0 50px 50px 0);
							}
						}
					}
				}
			}
		}
	}
// menu end
