// content
	.box {
		position: relative;
		width: 100%;max-width: 100%;
		padding: 20px 6px;margin-bottom: 20px;

		color: var(--color-text-secondary);

		background: $gl;
		overflow-y: auto;
		@include br(22px);

		@include lg {
			padding: 30px;
		}
		.box {
			background: $g;
		}
		table {
			&.clear {
				tr {
					th,td {background: none;}
				}
			}
		}
		.selectize-control.single .selectize-input {
			background: $gl;
			&.dropdown-active {
				background: $blue;
			}
		}
	}
// content
