// content
.chat {
    &ClearTop {
        padding-bottom: 0;

        * {
            margin-bottom: 10px;
        }
    }

    &Burger {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        padding: 0;

        @include lg {
            display: none;
        }

        a {
            @extend .burger;

            span {
                background: $blue;

                &:after,
                &:before {
                    background: $blue;
                }
            }

            &[aria-expanded="true"] {
                span {
                    background: none;

                    &:after,
                    &:before {
                        width: 26px;
                    }

                    &:before {
                        margin-top: 0;
                        background: $burgerLineActive;
                        transform: rotate(-45deg);
                    }

                    &:after {
                        margin-top: 0;
                        background: $burgerLineActive;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .sidebar {
        margin-bottom: 20px;
        background: $gl;
        @include br(20px);
        overflow: hidden;

        @include lg {
            display: block;
            height: 78vh;
        }

        .signedIn {
            padding: 20px 15px;

            @include lg {
                padding: 20px 34px;
            }

            .img {
                padding: 5px;

                a {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;

                    img {
                        display: inline-block;
                        width: 100%;
                        float: left;
                        margin: 0;
                        padding: 0;
                        @include br(100%);
                    }
                }
            }

            .details {
                padding: 6px;
                font-size: 9px;

                p {
                    margin: 0;
                }

                a {
                    text-decoration: none;
                }
            }
        }

        &UserList {
            max-height: 60vh;
            margin: 0;
            padding: 0 13px;
            padding-bottom: 25px;
            align-items: flex-start;
            overflow-y: auto;
            @include br(0);

            &Filtr {
                padding: 0 15px;

                @include lg {
                    padding: 0 26px;
                }
            }

            .form {
                margin-bottom: 15px;

                > .row {
                    > .col {
                        padding: 0;
                    }
                }

                input {
                    padding-left: 50px;
                    padding-right: 50px;
                    font-size: 10px;
                    line-height: 24px;
                    background: #ececec;

                    &[placeholder],
                    &::-webkit-input-placeholder,
                    &:-moz-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder {
                        font-style: italic;
                        opacity: 1;
                    }
                }

                button {
                    &[type="submit"] {
                        position: absolute;
                        top: 4px;
                        bottom: 4px;
                        right: 4px;
                        display: block;
                        width: 46px;
                        margin: auto;
                        padding: 4px;
                        color: $w;
                        font-size: 12px;
                        background: $blue;
                        text-align: center;

                        &:hover {
                            background: $blue2;
                        }
                    }
                }
            }

            &Single {
                background: $tableBg;
                margin-bottom: 5px;
                padding: 3px 20px;

                &:hover {
                    color: inherit !important;
                }

                .img {
                    padding: 5px;

                    a {
                        display: block;
                        float: left;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        img {
                            display: inline-block;
                            width: 100%;
                            float: left;
                            margin: 0;
                            padding: 0;
                            @include br(100%);
                        }
                    }
                }

                &.unread {
                    background-color: #ababab;

                    .details {
                        .messages-dot {
                            display: inline-block !important;
                        }
                    }
                }

                .details {
                    padding: 5px;
                    font-size: 9px;
                    font-weight: $medium;

                    p {
                        display: block;
                        max-width: 100%;
                        margin: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .messages-dot {
                            border-color: #f43644;
                            background: #f43644 !important;
                            padding: 4px;
                            top: 8px;
                            right: 0;
                            margin-left: 5px;
                            position: absolute;
                            border-radius: 50%;
                            display: none;
                        }
                    }

                    a {
                        text-decoration: none;
                    }

                    .name {
                        font-weight: $bold;
                        font-size: 11px;
                        margin: 0;
                    }
                }
            }
        }
        /* & + * {
				margin-bottom: 20px;
				>.box {
					padding-top: 85px;
					margin: 0;
				}
			} */
    }

    &Cont {
        padding: 0;
        margin-bottom: 20px;

        @include lg {
            padding: 0 15px;
        }

        > .box {
            padding-top: 80px;
            padding-bottom: 80px;
            margin: 0;

            @include lg {
                height: 78vh;
            }
        }

        .user {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: $tableBg;
            padding: 0 20px;

            @include br(22px);

            @include lg {
                padding: 0 45px;
            }

            .img {
                width: 52px;
                flex-basis: 52px;
                max-width: 52px;
                margin: 0;
                padding: 6px 5px;

                a {
                    display: inline-block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                img {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    @include br(100%);
                }
            }

            .details {
                padding: 6px 5px;
                font-size: 11px;
                font-weight: $bold;

                a {
                    text-decoration: none;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .chatMessages {
        /* max-height: 60vh; */
        /* max-height: calc(100vh - 280px); */
        max-height: calc(100vh - 345px);
        padding: 0 5px;
        overflow-y: auto;
        align-items: flex-end;

        @include lg {
            max-height: 100%;
            padding: 0 20px;
        }

        .messageSingle {
            padding: 0;

            .img {
                width: 52px;
                flex-basis: 52px;
                max-width: 52px;
                margin: 0;
                padding: 6px 5px;

                a {
                    display: inline-block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                img {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    @include br(100%);
                }
            }

            .details {
                max-width: calc(100% - 55px);
                padding: 5px;
                font-size: 13px;
                font-weight: $bold;

                a {
                    text-decoration: none;
                }

                p {
                    display: inline-block;
                    max-width: 80%;
                    margin: 0;
                    padding: 15px;
                    margin-bottom: 5px;
                    color: $w;
                    font-weight: $medium;
                    background: $blue;
                    @include br(19px);
                }

                .date {
                    display: inline-block;
                    width: 100%;
                    padding: 0px 15px;
                    margin-bottom: 5px;
                    font-size: 10px;
                    color: #2f3f7c;
                }
            }

            &.respons {
                p {
                    float: right;
                    color: var(--color-text-secondary);
                    background: $chatBoxGeay;
                }

                .date {
                    float: right;
                    text-align: right;
                }
            }
        }
    }

    .answereForm {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;

        form {
            margin: 0;
            padding: 15px 25px;
            background: $gl;

            @include lg {
                padding: 25px 50px;
            }

            > .row {
                > .col {
                    padding: 0;
                    margin: 0;
                }
            }

            input {
                padding: 5px 70px;
                font-size: 12px;
                line-height: 24px;
                background: $tableBg;

                &::placeholder {
                    color: black;
                    font-size: 12px;
                }

                &[placeholder],
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder {
                    font-style: italic;
                    opacity: 1;
                }

                &[type="submit"] {
                    position: absolute;
                    top: 4px;
                    bottom: 4px;
                    right: 4px;
                    display: block;
                    width: auto;
                    margin: auto;
                    padding: 4px 15px;
                    color: $w;
                    font-size: 12px;
                    line-height: 18px;
                    background: $green;
                    text-align: center;

                    &:hover {
                        background: $blue;
                    }
                }
            }
        }
    }
}

body.fixedHeader {
	.chat {
		.chatMessages {
			max-height: calc(100vh - 280px);

			@include lg {
				max-height: 100%;
			}
		}
	}
}
// content
