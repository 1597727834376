// content
	.why {
		padding: 30px 0;
		background: $whyBg;
		@include lg {
			padding: 50px 0;
		}
		.stats {
			padding-top: 5px;padding-bottom: 5px;
			margin-bottom: 30px;
			@include lg {
				margin-bottom: 50px;
			}
			h3 {

				font-weight: $xbold;
				margin: 0;
				@include lg {
					font-size: 40px;
				}
			}
			p {
				font-size: 18px;
			}
		}
		.label {
			margin-bottom: 30px;
			& + p {
				font-size: 12px;
				margin-bottom: 30px;
				@include lg {font-size: 14px;}
			}
		}
		&Boxes {
			.col {
				padding-bottom: 30px;
				margin-bottom: 15px;
			}
			&Single {
				position: relative;
				display: block;height: 100%;
				padding: 20px;
				font-size: 14px;
				background: $boxBg;
				overflow: visible;
				border-radius: 30px;
				@include lg {
					padding: 40px 30px;
				}
			}
			h4 {
				font-size: 16px;
				@include lg {font-size: 18px;}
			}
			img {
				display: inline-block;max-width: 100%;
				margin-bottom: 30px;
			}
			p {
				font-size: 12px;
			}
			a {
				&.btn {
					display: inline-block;width: auto;
					position: absolute;bottom: 0;left: 50%;

					transform: translateX(-50%) translateY(50%);
				}
			}
		}
	}
// content
