// content
@import '../rin_new_design/tokens/fonts.scss';
@import '../rin_new_design/tokens/colors.scss';

.app {
    padding: 30px 0;
    background: $appBg;

    .sportcam-wrapper {
        gap: 1rem;

        @include lg {
            gap: 6rem;
        }
    }

    .sportcam-footer {
        font-size: $font-size-h3;
        line-height: $font-height-h6;
        font-weight: $font-weight-body;
        color: black;
        white-space: pre-line;
    }

    .sportcam-title {
        font-size: 2rem;
        line-height: $font-height-body;
        font-weight: $font-weight-h1;
        color: black;
        margin-bottom: 3rem;
    }

    .sportcam-tilted {
        font-size: 2rem;
        line-height: $font-height-body;
        font-weight: $font-weight-h1;
        display: inline-block;
        align-items: center;
        color: #fff;
        background: $color-background-danger;
        padding: 0.25rem 0.4rem;
        transform: skewX(-25deg);
    }

    .cam-text {
        transform: skewX(25deg);
    }

    @media only screen and (max-width: 570px) {
        h2.sportcam-header {
            line-height: 2.25rem !important;
        }
    }

    h2.sportcam-header {
        margin-bottom: 1.6875rem;
        font-size: 2rem;
        line-height: $font-height-body;
        font-weight: $font-weight-h1;
        color: black;
    }

    p.sportcam-subheader {
        font-size: 1.5rem;
        font-weight: $font-weight-breadcrumbs;
        line-height: $font-height-body;
        color: black;
    }

    .download {
        width: 100%;
        margin: 0;
        padding: 30px 0;
        font-weight: $font-weight-not-so-bold;
        font-size: 1.5rem;
        line-height: $font-height-body;
        color: black;

        p {
            font-size: 20px;
            margin-bottom: 2rem;
        }

        a {
            margin: 1rem;
        }
    }
}
// content
