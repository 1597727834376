// menu
	/* new ver */
	.owl-carousel.owl-carousel-lpDesign,
	.owl-theme {
		.owl-dots {
			 display: flex; 
			 justify-content: center;
             align-items: center; 
			 position: absolute;
             bottom: 0;
             left: 0;
             right: 0; 

			/*display: inline-block;float: left;width: 100%;
			text-align: center;*/
			.owl-dot {
				display: inline-block;
                width: auto;
                height: auto;
				flex: 0 0 10px;
				margin: 0 5px;
                padding-bottom: 10px !important;
				outline: none;
				border: none;
				span {
					display: block;
                    float: left;
                    width: 10px;
                    height: 10px;
					margin: 0;
                    padding: 0;
					background: $owlDot_lpDesign;
					border: none;
					border: 2px solid $owlDotBorder_lpDesign;
					// border: 2px solid $b;
					transition: opacity 200ms ease 0s;
					transition-property: width, height, border-radius, border;
					transition-duration: .3s;
					@include br(5px);
				}
				&.active {
					span {
						// background: #869791 none repeat scroll 0% 0%;
						background: $owlDotActive_lpDesign;
						border-color: $owlDotBorderActive_lpDesign;
						border-style: solid;
					}
				}
			}
		}
	}
	/* old ver */
	.owl-carousel.owl-carousel-lpDesign,
	.owl-theme {
		.owl-wrapper,
		.owl-item {
			/*-webkit-backface-visibility: hidden;
			-moz-backface-visibility:    hidden;
			-ms-backface-visibility:     hidden;
			-webkit-transform: translate3d(0,0,0);
			-moz-transform: translate3d(0,0,0);
			-ms-transform: translate3d(0,0,0);*/
		}
		/* .owl-controls {
			position: relative;
			display: block;float: left;width: 100%;
			margin: 5px 10px;
			// bottom: 0;
			// left: 0;
			// right: 0;
			@media screen and (min-width: $sm) {
				// position: absolute;
			}
			.owl-dots {
				text-align: center;
				display: flex;
				float: left;
				display: inline-block;
				width: 100%;
				justify-content: center;align-items: center;

				.owl-dot {
					display: inline-block;
					flex: 0 0 10px;
					width: 8px;height: 8px;
					margin: 0 5px;
					span {
						display: block;float: left;width: 100%;height: 8px;
						margin: 0;padding: 0;
						background: $g_lpDesign;
						border: none;
						border: 2px solid $g_lpDesign;
						// border: 2px solid $b;
						transition: opacity 200ms ease 0s;
						transition-property: height, border-radius, border;
						transition-duration: .3s;
						@include br(0%);
					}
					&.active {
						span {
							// background: #869791 none repeat scroll 0% 0%;
							@include br(100%);
							background: $w;
							border-color: $w;
							border-style: solid;
						}
					}
				}
			}
		}
		.owl-stage-outer {
			display: inline-block;
			display: block;
			max-width: 100%;
		}
		.owl-stage {
			// display: flex;
			// flex-wrap: nowrap;
			// align-items: stretch;
			// height: 100%;
			// position: relative;
		}
		.owl-item {
			overflow: hidden;
			&.active {
				z-index: 9;
			}
		} */
	}
// menu end
