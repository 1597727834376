// burger
	.burger {
		/* position: absolute;top: 10px;right: 20px; */
		position: relative;
		display: flex;align-items: center;
		width: 40px;height: 40px;float: left;
		padding: 0;margin: 0;
		background: $burgerBg;
		cursor: pointer;
		pointer-events: auto;
		@include br(0px);
		// z-index: 9999999;
		@media screen and (min-width: $lg) {
			display: none;
			top: auto;left: auto;bottom: auto;right: auto;
			margin: 0;
		}
		span {
			position: absolute;left: 0;right: 0;top: calc(50% - 3px);
			display: block;width: 18px;height: 3px;
			margin: 0 auto;padding: 0;
			background-color: $burgerLine;
			@include br(50px);
			transition-property: background;
			transition-duration: .2s;
			transition-delay: .2s;
			&:before,
			&:after {
				content: '';
				position: absolute;right: 0;
				display: block;width: 26px;height: 3px;
				background-color: $burgerLine;
				@include br(50px);
				transition-property: margin,transform,-webkit-transform,width;
				transition-duration: .2s;
				/* transition-delay: .2s,0s,.2s,0s; */
				transition-delay: .2s;
			}
			&:before {
				margin-top: -9px;
			}
			&:after {
				margin-top: 9px;
				width: 9px;
			}
		}
		&.open {
			span {
				background: none;
				/* transition-delay: 0s, .2s; */
				&:after,
				&:before {
					width: 26px;
				}
				&:before {
					margin-top: 0;
					background: $burgerLineActive;
					transform: rotate(-45deg);
					/* transition-delay: 0s, .2s; */
				}
				&:after {
					margin-top: 0;
					background: $burgerLineActive;
					transform: rotate(45deg);
					/* transition-delay: 0s, .2s; */
				}
			}
		}
	}
// burger
