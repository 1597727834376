// content
	.lpDesign .label {
		display: inline-block;
		padding: 5px 30px;margin: 0;margin-bottom: 20px;
		font-size: 18px;line-height: 20px;color: $w;font-weight: $xbold;
		text-transform: none;
		background: $blue_lpDesign;
		border: 3px solid $blue_lpDesign;
		@include br(50px);
		&.label {
			&-lg {
				font-size: 24px;line-height: 25px;
				padding: 15px 35px;
				border: none;
			}
			&-sm {
				padding: 2px 15px;
				font-size: 12px;line-height: 14px;font-weight: $medium;
				border: none;
			}
			&-ghost {
				background: none;
				color: $blue_lpDesign;
			}
			&-primary {
				border-color: $bluel;
				background: $bluel;
			}
			&-success {
				border-color: $green;
				background: $green;
			}
			&-info {
				border-color: $blue2;
				background: $blue2;
			}
			&-warning {
				border-color: $orange;
				background: $orange;
			}
			&-danger {
				border-color: $red;
				background: $red;
				color: $w;
			}
		}
	}
// content
