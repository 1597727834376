// content
	.lpDesign .btn {
		padding: 4px 15px;
		font-size: 14px;line-height: 20px;color: $w;font-weight: $bold;
		text-transform: none;
		background: $blue_lpDesign;
		border: 2px solid $blue_lpDesign;
		@include br(50px);
		@include lg {
			padding: 4px 40px;
		}
		&.btn {
			&-g {
				border-color: $g_lpDesign;
				background: $g_lpDesign;
				color: $blue_lpDesign;
			}
			&-primary {
				border-color: $bluel;
				background: $bluel;
				&:hover {
					border-color: $blue_lpDesign;
					background: $blue_lpDesign;
				}
			}
			&-success {
				border-color: $green;
				background: $green;
			}
			&-info {
				border-color: $blue2;
				background: $blue2;
			}
			&-warning {
				border-color: $orange;
				background: $orange;
			}
			&-danger {
				border-color: $red;
				background: $red;
			}
			&-light {
				border-color: $blue_lpDesign;
				background: $w;
				color: $blue_lpDesign;
				&:hover {
					color :$w;
				}
			}
			&-ghost {
				border-color: $w;
				background: none;
				color: $w;
			}
			&-b {
				background: $b;
				border-color: $b;
				color: $w;
			}
			&-g {
				background: $g_lpDesign;
				border-color: $g_lpDesign;
				color: $blue_lpDesign;
			}
			&-lg {
				font-size: 18px;
				padding: 11px 50px;
			}
			&-sm {
				padding: 1px 15px;
			}
			&-menu {
				padding: 6px 15px;
			}
		}
		&:hover {
			background: $bluel;
			border-color: $bluel;
			color: $w;
		}
		&:focus,
		&:active {
			outline: none;
			box-shadow: none;
		}
	}
// content
