// content
	.videos {
		&List {
			&Single {
				margin-bottom: 10px;padding: 0;
				@include lg {
					padding: 0 15px;
				}
				.name {
					position: relative;
					display: block;float: left;width: 100%;
					margin: 0;padding: 0;margin-bottom: 10px;
					font-size: 9px;font-weight: $bold;
					clear: both;
					p {
						margin: 0;padding: 0;
					}
				}
				.video {
					position: relative;
					display: block;float: left;width: 100%;
					margin: 0;padding: 0;margin-bottom: 10px;
					clear: both;
					@include br(12px);
					overflow: hidden;
					img {
						display: block;float: left;width: 100%;max-width: 100%;
						margin: 0;padding: 0;
					}
					.play {
						position: absolute;top: 0;left: 0;right: 0;bottom: 0;
						display: block;width: 46px;height: 46px;
						margin: auto;padding: 0;
						color :$w;font-size: 20px;line-height: 46px;
						text-align: center;text-decoration: none;
						border: 2px solid $w;
						@include br(100%);
						&Alt {
							color: $gd;
							font-size: 46px;
							border: none;
							@include br(0);
						}
						&:hover {
							color: $blue2;
							border-color: $blue2;
						}
					}
				}
				.info {
					position: absolute;bottom: 0;left: 0;right: 0;
					display: flex;flex-wrap: nowrap;
					float: left;width: 100%;
					margin: 0;padding: 10px;
					color: $w;font-size: 9px;font-weight: $sbold;
					background: rgba($b,.6);
					p,a {
						margin: 0;
						text-decoration: none;
					}
					a {
						font-weight: $xbold;
					}
					.icon {
						display: inline-block;width: 14px;height: 14px;
						margin: 0;padding: 0 5px;
						line-height: 14px;
						text-align: center;
						&.icon-fb {
							border: 1px solid $w;
							@include br(4px);
						}
					}
				}
				.alternative {
					display: block;float: left;width: 100%;
					margin: 0;padding: 0;
					clear: both;
					font-size: 8px;
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
// content
