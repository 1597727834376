﻿.opt {
    position: relative;

    a {
        display: inline-block;
        transition-property: color, background, opacity;

        &:hover {
            color: var(--color-text-secondary);
            opacity: .5;
        }
    }

    &Menu {
        position: absolute;
        top: calc(100% + 14px);
        right: 0px;
        display: none;
        float: left;
        width: 110px;
        margin: 0 auto;
        padding: 13px;
        list-style: none;
        background: $w;
        transform: translateX( calc(50% - 7px) );
        will-change: filter;
        filter: drop-shadow(0 0 10px rgba($b,.3));

        @include br(14px);
        z-index: 9;

        li {
            a {
                display: block;
                float: left;
                width: 100%;
                margin: 0;
                margin-bottom: 4px;
                padding: 8px 5px 6px;
                font-size: 9px;
                font-weight: $bold;
                white-space: nowrap;
                background: $g;
                text-decoration: none;

                @include br(4rem);

                &:hover {
                    background: $bluel !important;
                    color: $w;
                }
            }
        }

        &.open {
            display: block;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            display: block;
            width: 16px;
            height: 16px;
            margin: auto;
            padding: 0;
            background: none;
            border-bottom: 14px solid $w;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
        }

        &.center-left {
            top: auto;
            bottom: 50%;
            right: calc(100% + 12px);
            left: auto;
            transform: translateY(50%);

            &:before {
                top: 0;
                bottom: 0;
                left: 100%;
                right: auto;
                transform: rotate(90deg);
            }
        }

        &.center-right {
            top: auto;
            bottom: 50%;
            left: calc(100% + 12px);
            right: auto;
            transform: translateY(50%);

            &:before {
                top: 0;
                bottom: 0;
                right: 100%;
                left: auto;
                transform: rotate(-90deg);
            }
        }

        &.center-bottom {
            top: calc(100% + 4px);
            bottom: auto;
            right: 0px;
            left: auto;
            transform: translateX( calc(50% - 7px) );

            &:before {
                top: -15px;
                right: 0;
                margin: 0 auto;
            }
        }
    }

    &.smaller {
        padding: 5px 5px 1px 5px;

        li {
            a {
                padding: 3px 3px 1px;
                margin-bottom: 2px;
                font-size: 9px;
            }
        }
    }
}
