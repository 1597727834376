// content

header {
    > .lpDesign {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        background: $headerBg;
        text-transform: none;
        z-index: 999999;
        transition-property: top;
        transition-duration: .3s;

        > .wrap {
            overflow: visible;

            @include lg {
                /* overflow: hidden; */
            }
        }

        .col {
            position: static;
        }

        .logo {
            width: auto;
            padding: 0;
            padding: cs(60, 30) 10px;
            transition-property: padding;
            transition-duration: .3s;
            transition-delay: 0s;

            @include lg {
                padding: 60px 10px;
            }

            a {
                display: block;
                float: left;
                width: 100%;
                margin: 0;
                padding: 0;
            }

            img {
                display: block;
                float: left;
                max-width: 100%;
                width: 200px;
                height: auto;
                margin: 0;
                padding: 0;
                transition-property: width, height;
                transition-duration: .3s;
                transition-delay: 0s;
            }
        }

        .searchTop.lpDesign {
            padding: 10px 0;

            @include lg {
                background: none;
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                bottom: auto;
                display: block;
                padding: 0 .5rem;
            }

            a {
                display: none;
                float: left;
                text-decoration: none;
                font-size: 20px;

                @include lg {
                    display: inline-block;
                }
            }

            form {
                position: relative;
                display: block;
                width: 100%;
                margin: 0;
                padding: 0 60px;
                background: none;

                @include lg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: none;
                    width: 230px;
                    padding: 0;
                }

                .row {
                    margin: 0;
                    padding: 0;

                    > .col {
                        margin: 0;

                        &:first-of-type {
                            padding-right: 0;
                        }

                        &:last-of-type {
                            padding-left: 0;
                        }
                    }
                }

                input,
                button {
                    height: 28px;
                    background: $gl;
                    color: $gd;
                    line-height: 18px;
                }

                input {
                    font-size: 12px;
                    @include br(18px 0 0 18px);
                }

                input,
                button {
                    &[type="submit"] {
                        @include br(0 18px 18px 0);

                        .icon {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        .profile {
            position: relative;
            padding: 10px 60px;
            text-align: center;

            @include lg {
                padding: 0 .5rem;
            }

            .icon {
                display: inline-block;
                font-size: 25px;
                padding: 0 5px;
            }

            a {
                color: $blue_lpDesign;

                &.btn {
                    color: $w;

                    &:hover {
                        color: $w;
                    }
                }

                &:not(.btn, .forgot) {
                    position: relative;
                    display: inline-block;
                    margin: 0;
                    color: $blue_lpDesign;
                    text-decoration: none;
                }

                &:hover {
                    &:not(.btn, .forgot) {
                        color: $blue2;
                    }
                }

                &.pro {
                    display: none;

                    @include lg {
                        display: inline-block;
                    }
                }
            }

            &Menu {
                display: block;
                float: left;
                max-width: 100%;
                height: auto;
                padding: 15px 0px;
                /* background: $w; */
                color: $blue_lpDesign;
                font-size: 12px;
                font-weight: $bold;
                will-change: filter;
                /* filter: drop-shadow(0 0 24px rgba($b,.26)); */
                @include lg {
                    position: absolute;
                    top: 100%;
                    left: -140px;
                    display: none;
                    width: 270px;
                    max-width: none;
                    padding: 30px 15px;
                    background: $w;
                    text-align: center;
                    transform: translateX(21%);
                    @include br(20px);
                    will-change: filter;
                    filter: drop-shadow(0 0 10px rgba($b,.3));
                }

                p {
                    margin: 0;
                }

                a {
                    display: block;
                    float: left;
                    width: 100%;
                    color: $blue_lpDesign;
                    text-align: left;

                    &.btn {
                        padding: 4px 15px;
                        text-align: center;
                        color: $w;
                    }
                }

                ul {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 20px;
                    border: none;
                    list-style-type: none;
                    text-align: center;

                    li {
                        padding: 0 10px;
                        margin-bottom: 4px;
                        width: 50%;

                        @include lg {
                            width: 100%;
                        }

                        a {
                            text-align: center;
                            color: $w;
                        }
                    }

                    &.open {
                        display: block;
                    }
                }

                form {
                    margin-bottom: 30px;

                    > .row {
                        > .col {
                            margin-bottom: 4px;
                            text-align: center;
                        }
                    }

                    input {
                        text-align: center;

                        &[type="checkbox"] {
                            & + label {
                                padding: 10px 0;
                                display: inline-block;
                                width: auto;
                                float: none;

                                &:after {
                                    margin-right: 5px;
                                }
                            }
                        }

                        &[type="submit"] {
                            float: none;
                            border: none;
                            margin: 10px 0;
                        }
                    }

                    a {
                        &.forgot {
                            font-size: 10px;
                            text-align: center;
                            text-decoration: underline;
                        }
                    }
                }

                .new {
                    display: block;
                    float: left;
                    width: 100%;
                    margin-bottom: 10px;

                    p {
                        margin-bottom: 10px;
                    }

                    a {
                        display: inline-block;
                        width: auto;
                        float: none;
                    }
                }

                .poweredBy {
                    display: block;
                    float: left;
                    width: 100%;
                    clear: both;
                    margin: 0;
                    padding: 18px 15px;
                    background: #ededed;
                    color: $blue_lpDesign;
                    border-radius: 16px;
                    text-align: center;

                    @include lg {
                        width: calc(100% + 30px);
                        margin: 0 -15px;
                        margin-bottom: -30px;
                    }

                    p {
                        margin: 0;
                    }

                    a {
                        display: inline-block;
                        float: none;
                        width: auto;
                        padding: 7px;
                    }
                }
            }
        }

        .lang.lpDesign {
            display: block;
            float: left;
            width: auto;
            margin: 0;
            padding: 15px 70px;
            pointer-events: auto;
            display: none;

            @include lg {
                display: inline-block;
                padding: 10px 20px;
            }

            &Mobile {
                display: inline-block;
                width: 100%;
                padding: 0;

                @include lg {
                    display: none;
                }
            }

            ul {
                position: relative;
                display: block;
                float: left;
                width: auto;
                height: 48px;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                z-index: 9;

                @include lg {
                    height: 38px;
                }

                li {
                    display: inline-block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 7px 0;
                    background: none;
                    clear: both;
                    display: none;
                    /*   background: $w;*/
                    background: white;

                    @include lg {
                        background: none;
                        background: $headerBg;
                        
                    }

                    a {
                        position: relative;
                        display: flex;
                        float: left;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        margin: 0;
                        color: $blue_lpDesign;
                        font-size: 14px;
                        text-decoration: none;
                        text-transform: none;
                        background: $w;
                        border: none;
                        cursor: pointer;
                        white-space: nowrap;

                        @media screen and (min-width: $lg) {
                            justify-content: flex-start;
                            padding: 0;
                            background: none;
                            color: $w;
                        }

                        .icon {
                            display: none;
                            font-size: 9px;
                            color: $blue_lpDesign;

                            @include lg {
                                color: $w;
                            }
                        }

                        .langName {
                            display: inline-block;
                            font-size: 10px;
                            font-weight: $light;
                        }


                        i {
                            display: inline-block;
                            float: none;
                            width: 29px;
                            height: 18px;
                            margin: 0;
                            padding: 0;
                            margin-right: 6px;

                            @include lg {
                                width: 39px;
                                height: 24px;
                            }
                        }

                        &:hover {
                            color: $blue2;
                            text-decoration: none;
                        }
                    }

                    &.active {
                        display: block;

                        a {
                            .icon {
                                display: inline-block;
                            }

                            .langName {
                                display: none;
                            }

                            &:hover {
                                color: $blue2;
                            }
                        }
                    }
                }

                &.open {
                    li {
                        a {
                            .langName {
                                /* display: inline-block; */
                            }
                        }
                    }
                }
            }
        }

        &.fixed {
            position: fixed;
            top: -48px;

            .logo {
                padding: 5px 7px;
                transition-delay: 1s;

                @include lg {
                    padding: 10px;
                }

                img {
                    width: 25px;
                    height: 29px;
                    transition-delay: 1s;
                }
            }

            .fixedMobileBack {
                display: block;

                @include lg {
                    display: none;
                }
            }
        }
    }
}
// content