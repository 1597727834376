// menu
.menuLpDesign {
	position: absolute;top: 100%;right: 0;
	display: none;
	float: left;max-width: 100%;height: auto;
	max-height: calc(100vh - 180px);
	margin: 0;padding: 10px 0;
	font-weight: $bold;color: $w;
	border: none;
	background: $w;
	z-index: 999990;
	@include br(22px 0 0 22px);
	overflow-y: auto;
	@include lg {
		/* position: relative; */
		position: static;
		top: auto;left: auto;
		display: block;height: auto;width: auto;
		background: none;
		overflow: visible;
	}
	&.open {
		display: block;
	}

	&Wrapper {
		padding: 0 60px;
		display: block;float: left;width: auto;
		@include lg {
			padding: 0 10px;
		}
	}

	ul {
		display: block;float: left;width: 100%;
		padding: 0;margin: 0 auto;
		list-style: none;
		clear: none;
		border: none;
		/* border-top: 1px solid $gl; */
		@media screen and (min-width: $lg) {
			border: none;
		}

		li {
			display: block;float: left;width: 50%;height: auto;
			padding: 5px 10px;
			/* overflow: hidden; */
			clear: none;
			text-align: center;
			@media screen and (min-width: $lg) {
				display: inline-block;width: auto;float: left;
				padding: 0;
				border: none;
			}

			a {
				position: relative;
				display: inline-block;float: left;width: 100%;
				padding: 5px 15px;margin: 0;
				text-decoration: none;text-align: right;text-transform: none;
				background: $blue_lpDesign;
				border: none;
				text-align: center;text-transform: none;
				cursor: pointer;
				@include br(50px);
				transition-property: color, background;
				transition-duration: 0.3s;
				@media screen and (min-width: $lg) {
					display: block;
					float: left;
					width: 100%;
					border: none;
					background: none;
					text-align: left;
				}
				.icon {
					padding: 0 5px;
					&.icon-play {
						/* position: absolute; */top: 0px;right: 3px;
						display: inline-block;width: 14px;height: 14px;
						margin: 0;padding: 0;padding-left: 2px;
						font-size: 8px;color: $w;line-height: 14px;
						text-align: center;vertical-align: middle;
						background: $red;
						@include br(100%);
						@include lg {
							position: absolute;top: 0px;right: 3px;

						}
					}
				}
			}

			&.hasSub,
			&.menu-item-has-children {
				position: relative;
				overflow: visible;
				> a {
					padding-right: 30px;
					@include br(50px);
					@include lg {
						border: 2px solid $w;
					}
					&:hover {
						background: $bluel;
						color: $w;
					}
					&:after {
						position: absolute;top: 0;bottom: 0;right: 10px;
						display: inline-block;width: 16px;height: 6px;
						margin: auto;
						/* content: '⌄'; */
						content: '\e91a';
						display: inline-block;
						font-family: $ico;font-size: 6px;line-height: 1;
						vertical-align: middle;
					}
				}
				ul {
					position: relative;
					display: none;float: left;width: 100%;height: auto;
					margin: 0;padding: 0;padding-top: 15px;padding-bottom: 10px;
					color: $blue_lpDesign;
					background: $w;
					border: none;
					z-index: 999;
					@include lg {
						position: absolute;top: 100%;left: 0;
						min-width: 360px;height: auto;width: auto;
						border: none;
						@include br(20px);
						/* overflow: hidden; */
						overflow: visible;
						will-change: filter;
						filter: drop-shadow(0 0 10px rgba($b,.3));
					}
					li {
						display: block;float: left;width: 100%;
						/* margin: 0 5px;padding: 5px 15px; */
						margin: 0;padding: 0;
						border: none;
						text-align: left;
						/* border-bottom: 1px solid $blue_lpDesign; */
						p {
							margin: 0;padding: 0 15px;margin-bottom: 6px;
							font-size: 9px;
							@include lg {
								padding: 0 30px;
							}
						}
						a {
							padding: 5px 15px;
							color: $w;
							text-align: center;
							background: $blue_lpDesign;
							@include br(13px);
							&:hover {
								color: $w;
								background: $bluel;
								border-color: $bluel;
							}
						}
						ul {
							position: relative;
							display: block;width: 100%;float: left;
							margin: 0;padding: 0;margin-bottom: 10px;
							filter: none;
							@include lg {
								padding: 0 14px;
							}
							li {
								/* margin: 0 5px;padding: 5px 15px; */
								/* width: calc(100% - 10px); */
								margin: 0 0px;padding: 5px 0px;
								@include lg {
									padding: 2px 4px;
									/* margin: 0 5px;padding: 5px 15px;
									max-width: calc(50% - 10px); */
									max-width: 50%;
								}
							}
						}
						&.userManual {
							margin: 0;padding: 5px 0;
							font-size: 8px;
							@include lg {
								padding: 5px 20px;
							}
							a {
								width: auto;float: right;
								padding: 1px 9px;
								background: $blue2;
								&:hover {
									background: $bluel;
								}
							}
						}
						&.menuMore {
							color: $green;
							a {
								background: $green;
								border-color: $green;
								&:hover {
									color: $w;
									background: $bluel;
									border-color: $bluel;
								}
							}
						}
					}
				}
			}
			&.active{
				> a {
					&:after {
						transform: rotate(180deg);
					}
				}
				ul {
					display: block;
				}
			}
		}
	}
}
// menu end
