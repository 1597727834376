// content
	.features {
		padding: 30px 0;
		background: $blue_lpDesign;
		color :$w;
		@include lg {
			padding: 80px 0 60px;
		}
		h2 {
			@include lg {
				margin-bottom: 70px;
			}
		}
		&Single {
			text-align: center;
			margin-bottom: 20px;
			@include lg {
				margin-bottom: 40px;
			}
			.icon {
				display: inline-block;width: 100%;
				margin: 0;padding: 0;margin-bottom: 10px;
				img {
					display: inline-block;max-width: 100%;
				}
			}
			h4 {
				margin-bottom: 25px;
				font-size: 16px;
				@include lg {font-size: 18px;}
			}
			p {
				margin: 0;
				font-size: 12px;
				@include lg {font-size: 13px;}
			}
		}
	}
// content
