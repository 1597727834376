// content
.sidebar {
	padding: 0 8px;
	margin-bottom: 20px;

	@include lg {
		padding: 0;
	}

	table {
		tr {
			td, th {
				/* padding: 5px 10px; */
				padding-left: 10px;
				padding-right: 10px;

				img {
					display: inline-block;
					margin: 0;
					padding: 0;
					padding-right: 5px;
				}

				.flag {
					img {
						padding: 0;
					}
				}
			}
		}
	}

	&Rank {
		.ranking-dropdown {
			color: #fff;
			background: #2f3f7c;
		}

		.dropdown-toggle:after {
			right: 5px;
		}

		table {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	.member {
		margin-bottom: 10px;
	}

	.partners {
		margin-bottom: 10px;

		a {
			display: block;
			float: left;
			width: 100%;
			margin: 0 auto;
			padding: 0;
			margin-bottom: 10px;

			img {
				display: inline-block;
				width: auto;
				max-width: 100%;
				margin: 0;
				padding: 0;
			}
		}
	}

	.box {
		padding: 12px 10px;
		margin-bottom: 10px;

		p, a {
			margin: 0;
		}

		& + * {
			&:not(.box) {
				margin-top: 30px;
			}
		}
	}

	&.teamInfo {
		font-size: 9px;
		font-weight: $bold;

		.logo {
			margin-bottom: 15px;

			a {
				display: inline-block;
				float: left;
				width: auto;
				margin: 0;
				padding: 0;
			}

			img {
				width: auto;
				height: auto;
				min-width: 100%;
				margin: 0;
				padding: 0;
			}
		}

		.name {
			margin-bottom: 18px;

			h2 {
				font-size: cs(20,14);
				margin: 0;
				margin-bottom: 10px;

				@include lg {
					font-size: 20px;
				}
			}

			p {
				font-size: cs(12,9);
				margin: 0;
				margin-bottom: 10px;

				@include lg {
					font-size: 12px;
				}
			}
		}

		.details {
			margin-bottom: 18px;
			font-size: 10px;

			.icon,
			.flag {
				display: inline-block;
				width: 17px;
				height: auto;
				margin: 0;
				padding: 0;
				padding-right: 5px;
				font-size: 14px;
				line-height: 16px;
			}

			p {
				margin-bottom: 3px;
			}
		}

		.admin {
			font-size: 10px;

			p {
				margin-bottom: 3px;
			}

			.sameWidth {
				display: inline-block;
				width: 180px;
			}

			a {
				&.btn {
					padding: 1px 5px;
					font-size: 8px;
				}
			}
		}
	}

	&UserList {
		color: var(--color-text-secondary);

		h4 {
			padding: 0 20px;
			font-size: 16px;
		}

		&Single {
			margin-bottom: 15px;

			.img {
				padding: 0 5px;

				a {
					display: block;
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;

					img {
						display: block;
						float: left;
						width: 100%;
						margin: 0;
						padding: 0;
						@include br(100%);
					}
				}
			}

			.details {
				.name {
					margin-bottom: 7px;
					font-size: 12px;
					font-weight: $sbold;
				}

				.flag {
					margin-bottom: 10px;
					font-size: 10px;
					line-height: 2;
					font-weight: $sbold;

					img {
						display: block;
						float: left;
						width: auto;
						height: auto;
						max-width: 30px;
						max-height: 20px;
						margin: 0;
						padding: 0;
						margin-right: 5px;
						line-height: 1;
						vertical-align: middle;
					}
				}

				a {
					&.btn {
						margin: 0;
						font-size: 12px;
					}
				}
			}
		}
	}

	.apps {
		a {
			&.btn {
				margin-bottom: 10px;
				padding: 5px 30px;

				.icon {
					font-size: cs(40,20);

					@include lg {
						font-size: 40px;
					}
				}
			}
		}
	}

	.fullButtons {
		margin-bottom: 10px;

		a {
			width: 100%;
			margin-bottom: 10px;
			text-align: center;
		}
	}
}
// content
