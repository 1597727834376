// menu
.selectize {
	&-control {
		display: block;
		float: left;
		width: 100%;
		min-width: 100px;
		margin: 0;
		padding: 0;

		&.single {
			.selectize-input {
				-webkit-appearance: none;
				-moz-appearance: none;
				outline: none;
				display: block;
				width: 100%;
				float: left;
				padding: 3px 1rem;
				margin: 0;
				padding-right: 30px;
				color: var(--color-text-secondary);
				font-family: $mainFont;
				font-size: 11px;
				line-height: 1.125;
				font-weight: $bold;
				text-transform: none;
				background: $w;
				border: 2px solid $blue;
				box-shadow: none;
				cursor: pointer;
				@include br(50px);

				div {
					width: auto;
				}

				.item {
					font-style: normal;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
				}

				input {
					font-size: inherit;
					position: absolute;
					color: $w;
					font-weight: $bold;
				}

				&.input-active {
					display: block;
				}

				&:before {
					display: none;
				}

				&:after {
					content: '';
					content: "\e91a";
					display: block;
					width: 10px;
					height: 10px;
					right: 10px;
					top: 7px;
					font-family: $ico;
					font-size: 5px;
					line-height: 10px;
					color: $b;
					text-align: center;
					border: none;
					background: none;
					transform-origin: center center;
					transition-property: transform;
					transition-duration: .3s;
				}

				&.dropdown-active {
					background: $blue;
					color: $w;

					&:after {
						transform: rotate(180deg);
						color: $w;
					}

					& + .selectize-dropdown {
						padding-top: 5px;
						padding-bottom: 5px;
					}
				}
			}
		}

		&.blue {
			&.single {
				.selectize-input {
					color: $w;
					background: $blue;

					&:after {
						color: $w;
					}
				}
			}
		}
	}

	&-input {
		display: block;
	}

	&-dropdown {
		left: 0;
		right: 0;
		/* display: block!important; */
		max-width: 80%;
		margin: auto;
		background: $tableOnWhiteBg;
		border: none;
		overflow: hidden;
		will-change: filter;
		filter: drop-shadow(0 0 18px rgba($b,.25));
		@include br( 0 0 12px 12px);
		z-index: 99999;

		.option {
			padding: 5px 1rem;
			margin: 0;
			padding-right: 30px;
			margin-bottom: 5px;
			color: var(--color-text-secondary);
			font-family: $mainFont;
			font-size: 11px;
			line-height: 1.125;
			font-weight: $bold;
			white-space: nowrap;
			text-transform: none;
			box-shadow: none;
			cursor: pointer;

			&:last-of-type {
				margin: 0;
			}

			&:hover,
			&.selected {
				background: $g;
				/* background: $blue;
					color: $w; */
			}

			.col {
				margin: 0;
				padding: 0 5px;
			}

			.flag {
				width: 20px;
				max-width: 20px;
				flex-basis: 20px;
				padding: 0 3px;
				text-align: center;

				.icon {
					display: inline-block;
					font-size: 14px;
				}
			}

			img {
				display: inline-block;
				float: left;
				width: 14px;
				height: 11px;
				margin: 0;
				padding: 0;
			}
		}
	}
}
// menu end
