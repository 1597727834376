// content
.mainLpDesign {
	padding-top: 90px;
	background: $blue_lpDesign;
	background-image: none;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top left;
	color: $w;

	@include lg {
		padding-top: 145px;
		background-image: url('https://cdn.rankedin.com/images/static/mainBg.jpg');
	}

	.clearBox {
		/* padding: 30px 15px; */
		padding: 30px 0px;
		filter: drop-shadow(0 0 20px $blue_lpDesign);

		@include lg {
			padding: 80px 0px 30px 30px;
		}

		h1 {
			margin: 0;
			font-size: 50px;
			font-size: cs(80,55,1420,320);
			font-weight: $black;
			/* font-size: 100px; */
			line-height: 1;

			@include xlg {
				font-size: 89px;
			}

			.col {
				padding: 0;
				padding-left: 0;

				&:last-of-type {
					padding-right: 0;
				}

				@include lg {
					padding-right: 5px;
				}
			}

			.small {
				padding-left: cs(10,1,480,320);
				font-size: 25px;
				font-size: cs(35,25,1420,320);
				line-height: .9;
				font-weight: $black;

				@include sm {
					padding-left: 10px;
				}

				@include xlg {
					font-size: 39px;
				}
			}

			& + p {
				font-size: 10px;
				font-weight: $sbold;
				text-align: center;

				@include lg {
					margin-bottom: 55px;
					font-size: 14px;
					text-align: left;
				}
			}
		}

		h4 {
			margin-bottom: 20px;
			font-size: 12px;
			text-align: center;

			@include lg {
				margin-bottom: 40px;
				font-size: 20px;
				text-align: left;
			}
		}
	}

	.wrap {
		@include lg {
			padding-top: 90px;
			padding-bottom: 70px;

			> .row {
				padding: 0 30px;
			}
		}
	}

	.boxLpDesign {
		background: $mainBoxBg;

		@include lg {
			padding: 80px 60px 40px;
		}

		h2 {
			font-size: cs(30,18,1420,320);

			@include xlg {
				font-size: 30px;
				margin-bottom: 60px;
			}
		}

		form {
			margin-bottom: 30px;

			> .row {
				> .col {
					margin-bottom: 20px;
				}
			}

			@include lg {
				margin-bottom: 70px;
			}

			label {
				/* font-size: initial; */
				font-size: cs(14,9,1420,320);

				@include xlg {
					font-size: 14px;
				}
			}
		}

		.links {
			margin: 0;
			font-size: cs(14,9,1420,320);

			@include xlg {
				font-size: 14px;
			}

			p {
				margin: 0;
			}

			a {
				display: inline-block;
				padding: 0 10px;
				margin: 0;
				font-weight: $bold;

				&:first-of-type {
					padding-left: 0;
				}

				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}
}
// content
