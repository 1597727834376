// content
	div.notyficationLpDesign {
		&Single {
			padding: 15px;margin-bottom: 20px;
			font-size: 13px;
			font-weight: $medium;
			text-align: center;
			background: $notyficationBg;
			border-radius: 18px;
			@include lg {
				border-radius: 22px;
			}
			p {
				margin: 0;padding: 0;
			}
		}
		&-primary {
			border-color: $notyficationPrimary;
			background: $notyficationPrimary;
		}
		&-success {
		color: #155724;
		background-color: #d4edda;
		border-color: #c3e6cb;
	}
	&-info {
		border-color: $notyficationInfo;
		background: $notyficationInfo;
	}
	&-warning {
		color: #856404;
		background-color: #fff3cd;
		border-color: #ffeeba;
	}
		&-danger {
			border-color: $notyficationDanger;
			background: $notyficationDanger;
			color: $red;
		}
		&.rounded-pill {
			&-lg {
				/* border-radius: 0; */
				@include lg {
					border-radius: 50rem;
				}
			}
		}
		input {
			&[type="radio"],
			&[type="checkbox"] {
				& + label {
					position: relative;
					display: inline-block;width: auto;
					padding-left: 30px;
					&:after {
						position: absolute;
						top: -2px;left: 0;
						width: 20px;height: 20px;
						background: $w;
						font-size: 14px;line-height: 20px;
					}
				}
			}
		}
	}
// content
