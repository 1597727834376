// content
	.mainPageLpDesign {
		padding: 0!important;
		header.lpDesign {
			@include lg {
				background: none;
			}
			.lang {
				ul {
					li {
						@include lg {
							background: $blue;
						}
					}
				}
			}
		}
	}
// content
