// colors
	$w: #fff;
	$b: #000;

	$g: #999999;
	$gd: #333333;
	$gl: #ededed;

	$red: #f00;
	$green: #0f0;
/*	$blue: #00f;*/
	$bluel: #11f;
	$blued: #006;
	$yellow: #FFFF00;

	$formBorder: $b;

	$fb: #3b5999;
	$tw: #13aced;
	$yt: #e34436;
	$in: #0077B5;
	$ins: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
	$google: #E7711B;



	/* new */
	$gl: #f8f8f8;
	$g: #e8e8e8;
	$g_lpDesign: #e6e6e6;
	$gd: #9b9b9b;

	$tableBg: #ececec;
	$tableDarkerBg: #E6E6E6;
	$tableOnWhiteBg: #f3f3f3;
	$boxBg: #f6f6f6;
	$boxBoxBg: #ebebeb;
	$mainBoxBg: #3b4b8a;
	$testimonialsBg: #435394;
	$testimonialsBoxBg: #56659f;
	$appBg: #e5e5e5;
	$contactBg: #3b4b8a;


	$blue: #2f3f7c; 
	$blue_lpDesign: #2e3d7e;
	$headerBg: #23306c;
	$blue2: #00b9ff;
	$bluel: #4f61a5;
	$chatBoxBlue: #6779bc;
	$chatBoxGeay: $tableBg;

	$whyBg: #eaeaea;

	$green: #37d076;

	$orange: #ff9f1b;

	$red: #f43644;

	$yellow: #ffde00;

	/* classes */
	$cl00: #a3a3a3;
	$cl01: #356bdd;
	$cl02: #d83b37;
	$cl03: #2f3f7c;
	$cl04: #1aaa56;
	$cl05: #eb4a90;
	$cl06: #8f449e;
	$cl07: #2c6f6b;
	$cl08: #8a2c40;
	$cl09: #f05a2f;
	$cl10: #f2a701;
	$cl11: #00b9ff;
	$cl12: #9ed82e;
	$cl13: #689599;
	$cl14: #624151;
	$cl15: #182952;
	$cl16: #2b3595;
	$cl17: #3c6562;
	$cl18: #3e432e;
	$cl19: #404b69;
	$cl20: #4d4545;
	$cl21: #55458c;
	$cl22: #d655b8;

/* classes */
$notyficationBg: #e7e9f0;
	$notyficationDanger: #fbe6e7;
	$notyficationWarning: $orange;
	$notyficationInfo: $blue2;
	$notyficationSuccess: $green;
	$notyficationPrimary: $bluel;

	$buttonBorder: #af938f;

	$burgerBg: none;
	$burgerLine: $w;
	$burgerLineActive: $blue2;
	$owlDot: $g;
	$owlDotBorder: $g;
	$owlDotActive: $blue2;
	$owlDotBorderActive: $blue2;
	$owlDot_lpDesign: $g_lpDesign;
	$owlDotBorder_lpDesign: $g_lpDesign;
	$owlDotActive_lpDesign: $green;
	$owlDotBorderActive_lpDesign: $green;
// colors end
