// content
.profile {
    &Tabs {
        padding: 0 8px;

        @include lg {
            padding: 0 15px;
        }

        > .row {
            -webkit-transform-origin: 50% 51%;
            transform-style: preserve-3d;
            backface-visibility: visible;
            perspective: 1;
        }

        .boxTabTitle {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;

            @include lg {
                flex-wrap: nowrap;
            }

            a {
                flex: 1 1 26%;
                padding: 6px 15px;
                margin: 0 3px;
                color: $w;
                font-size: 10px;
                font-weight: $bold;
                text-align: center;
                background: $bluel;
                @include br(12px 12px 0 0);

                &[aria-expanded="true"] {
                    background: $blue;
                }
            }
        }

        .boxTabCont {
            padding: 0;
            padding: 15px;
            background: $gl;
            @include br(15px);

            @include lg {
                padding: 30px 25px;
            }

            .sidebar-placeholder {
                img {
                    display: block;
                    margin-top: 3em;
                    margin-bottom: 3em;
                }

                a {
                    margin-bottom: 3em;
                }

                p {
                    margin-bottom: 3em;
                    font-size: 1em;
                }
            }

            .row {
                > .col-12,
                > .col {
                    overflow-y: auto;
                }
            }

            table {
                tr {
                    td, th {
                        border-color: $gl;

                        a, span {
                            white-space: nowrap;
                        }

                        .flag {
                            img {
                                display: inline-block;
                                margin: 0;
                            }
                        }

                        .box {
                            display: inline-block;
                            min-width: 22px;
                            height: 32px;
                            flex: 0 0 22px;
                            max-width: 100%;
                            margin: 0 1px;
                            padding: 2px 0;
                            color: var(--color-text-secondary);
                            font-weight: $sbold;
                            text-align: center;
                            background: $w;
                            @include br(6px);
                            overflow: hidden;
                        }
                    }
                }
            }

            h4 {
                position: relative;
                display: inline-block;
                width: 100%;
                margin: 0;
                padding: 0;
                padding-right: 25px;
                /* margin-bottom: 14px; */
                margin-bottom: 6px;
                font-size: 14px;
            }

            p {
                font-size: 10px;
                margin-bottom: 5px;
            }

            .buttons {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                /* justify-content: space-between; */
                margin-bottom: 10px;

                .btn {
                    flex: 0 0 auto;
                    max-width: 100%;
                    margin: 2px 3px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            .infoIcon {
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;
                width: 19px;
                height: 19px;
                margin: 0;
                padding: 0;
                color: $w;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                background: $blue;
                @include br(100%);

                &:hover {
                    background: $blue2;
                }
            }
        }

        &.fixed {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            /* background: $w; */
            z-index: 99999;
            filter: drop-shadow(0 0 10px rgba($b,.3));

            .boxTabCont {
                max-height: calc(100vh - 170px);
                overflow-y: auto;
                padding: 0 15px;

                .collapse {
                    padding: 15px 0;
                }
            }
        }
    }

    &MainInfo {
        .img {
            text-align: center;

            .img-wrapper {
                position: relative;
                padding-bottom: 25px;

                .edit-promo-avatar {
                    position: absolute;
                    bottom: 5px;

                    @include lg {
                        bottom: 0;
                    }
                }

                .edit-promo-avatar-btn {
                    height: 30px;
                    width: 30px;
                    background-color: white;
                    color: #2f3f7c;
                    border-radius: 50%;
                    display: inline-block;
                    border: 4px solid #f1f1f1;
                    line-height: 24px;
                    font-size: 16px;

                    @include lg {
                        height: 50px;
                        width: 50px;
                        line-height: 43px;
                        font-size: 20px;
                    }
                }

                .dropdown-menu {
                    left: -30% !important;
                    right: unset !important;
                    padding: 10px 10px 5px 10px;
                    border-radius: 25px;

                    label {
                        cursor: pointer;
                    }
                }
            }

            img {
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 100%;
                margin: 0 auto;
                padding: 0;
                @include br(100%);

                @include lg {
                    width: 100%;
                }
            }
        }

        .desc {
            padding: 0;
            padding-top: 10px;

            @include lg {
                padding: 0 15px;
            }

            h2 {
                font-size: cs(33,18);
                font-weight: $xbold;
                line-height: 1;

                @include lg {
                    font-size: 33px;
                }

                .bigger {
                    font-size: cs(43,24);

                    @include lg {
                        font-size: 43px;
                    }
                }
            }

            .id {
                font-weight: $sbold;

                .flag {
                    display: inline-block;
                    width: 33px;
                    height: 25px;
                    padding: 0;
                    margin: 0;
                    margin-right: 5px;
                    vertical-align: middle;

                    img {
                        display: block;
                        float: left;
                        width: 33px;
                        height: 25px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            .buttons {
                float: left;
                width: auto;
                padding: 0 5px;

                @include lg {
                    float: none;
                    width: auto;
                    margin: 0;
                }

                .btn {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 auto;
                    flex: 0 0 auto;
                    max-width: 100%;
                    margin: 2px 3px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                a {
                    margin-bottom: 10px;

                    &:not(.btn) {
                        display: inline-block;
                        margin-bottom: 10px;
                        font-size: 20px;
                        line-height: 1;
                        color: #dedede;
                        text-decoration: none;

                        &:hover {
                            color: var(--color-text-secondary);
                        }
                    }
                    /*&.btn {
						padding-left: 10px;
						padding-right: 10px;
					}*/
                }
            }

            .box {
                padding: 15px;
                font-size: 12px;
                font-weight: $normal;

                p {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    .submenu {
        @include lg {
            padding: 35px 15px 60px 15px;
        }

        ul {
            li {
                flex: 1 1 auto;
                max-width: 100%;
                margin: 0 3px;

                a {
                }
            }
        }

        &.fixed {
            padding: 0 20px;

            > ul {
                /* padding: 10px 0; */
            }
        }
    }

    &Cont {
        > .col {
            padding: 0;

            @include lg {
                padding: 0 15px;
            }
        }

        > * {
            overflow-y: auto;
        }

        .history-placeholder {
            h4 {
                margin-bottom: 3em;
            }

            img {
                display: block;
                margin-bottom: 3em;
            }
        }

        .no-latest-event {
            h4 {
                margin-bottom: 3em;
            }

            img {
                display: block;
                margin-bottom: 3em;
            }

            a {
                margin-bottom: 3em;
            }
        }




        table {
            tr {
                th {
                }

                th, td {
                    font-size: 10px;
                    padding: 6px 10px;

                    &:first-of-type {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    &:last-of-type {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    span {
                        &:not(.row) {
                            white-space: nowrap;
                        }
                    }

                    .icon {
                        font-size: 13px;
                    }

                    a {
                        white-space: nowrap;

                        &.btn {
                            font-size: 8px;
                            padding: 0 3px;
                        }
                    }
                }
            }
        }

        .overview {
            a {
                cursor: pointer;
                display: inline-block;
                padding: 1px 15px;
                font-size: 11px;
                font-weight: $bold;
                text-decoration: none;
                @include br(50px);

                &:not(.btn) {
                    text-decoration: none;
                }

                &.active {
                    border: 2px solid $blue;
                }
            }

            table {
                th {
                    background: $blue;
                    color: $w;
                }

                th, td {
                    border: 2px solid $gl;
                    @include br(8px);

                    .big {
                        font-size: 15px;
                    }

                    .small {
                        font-size: 9px;
                    }
                }
            }
        }

        .chart {
            canvas {
                min-width: 400px;
                min-height: 200px;
            }
        }

        .events {
            .attr {
                font-size: 9px;
                font-weight: $sbold;

                .flag {
                    display: inline-block;
                    width: 19px;
                    height: 11px;
                    padding-right: 5px;

                    img {
                        display: inline-block;
                        width: 14px;
                        height: 11px;
                        margin: 0;
                        padding: 0;
                        vertical-align: middle;
                    }
                }

                a {
                    text-decoration: none;
                }
            }

            p {
                a {
                    text-decoration: none;
                }
            }

            table {
                tr {
                    td, th {
                        font-size: 9px;
                        font-weight: $sbold;

                        a {
                            text-decoration: none;
                        }

                        span {
                            padding: 0 5px;
                        }

                        .row {
                            /* display: inline-block; */
                            width: auto;
                        }

                        .result {
                            display: inline-block;
                            padding: 2px 10px;
                            background: $blue;
                            color: $w;
                            font-size: 9px;
                            white-space: nowrap;
                            @include br(50px);
                        }
                    }
                }
            }
        }
    }

    .profile-match {
        .winner {
            font-weight: bold;
        }
    }
}

#myDonateModal {
    font-size: 13px;

    .donate-info {
        margin-bottom: 2rem !important;
    }

    .donate-amount-text {
        margin-top: 5px;
    }

    .modal-header {
        display: block;
    }

    .modal-title {
        text-align: center;
    }

    .credit-or-debit {
        margin: 1rem 0 !important;
    }   
}

.icon-dots {
    cursor: pointer;
    padding: 3px;
}

.rin-three-dots-menu {
    padding: 5px 5px 1px 5px;
    top: auto;
    bottom: 50%;
    right: calc(100% + 12px);
    left: auto;
    transform: translateY(50%);
    position: absolute;
    float: left;
    width: 110px;
    margin: 0 auto;
    list-style: none;
    background: #fff;
    will-change: filter;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    border-radius: 14px;
    z-index: 9;
    display: none;
}

.rin-three-dots-menu.open {
    display: block;
}

.rin-three-dots-menu::before {
    top: 0;
    bottom: 0;
    left: 100%;
    right: auto;
    transform: rotate( 90deg);
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    margin: auto;
    padding: 0;
    background: none;
    border-bottom: 14px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}

.rin-three-dots-menu button,
.rin-three-dots-menu a {
    display: block;
    float: left;
    font-size: 11px;
    line-height: 13px;
    width: 100%;
    font-weight: 700;
    background: #E4E5ED;
    color: #2F3F7D;
    margin-bottom: 4px;
    margin: 0 0 5px 0;
    padding: 3px;
    white-space: nowrap;
    text-decoration: none;
    -webkit-border-radius: 4rem;
    -moz-border-radius: 4rem;
    border-radius: 4rem;
    text-align: center;
}

.rin-three-dots-menu button:focus,
.rin-three-dots-menu a:focus {
    border: none !important;
}
// content
