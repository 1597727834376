// content
	.organizationLpDesign {
		padding: 30px 0;
		background: $whyBg;
		@include lg {
			padding: 100px 0 70px;
		}
		.img {
			text-align: center;
			img {
				display: inline-block;max-width: 100%;
				margin: 0;padding: 0;
				margin-bottom: 30px;
				@include lg {
					max-width: calc(100% + 110px);
					margin: 0;margin-left: -30px;
				}
			}
		}
		.desc {
			@include lg {
				padding-top: 50px;
				padding-left: 80px;
			}
			h3 {
				font-size: 17px;
				@include lg {font-size: 30px;}
			}
			p {
				margin-bottom: 30px;
				font-size: 12px;
				@include lg {font-size: 14px;}
			}
		}
	}
// content
