// content
	.partners {
		padding: 30px 0;
		@include lg {
			padding: 60px 0 40px;
		}
		h2 {
			margin-bottom: 10px;
		}
		p {
			font-size: 12px;
			@include lg {font-size: 14px;}
		}
		&List {
			padding: 0;margin: 0;
			ul {
				display: -webkit-flex;
				display: -ms-flex;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin: 0;padding: 0;
				list-style: none;
				&.owl-carousel.owl-carousel-lpDesign {
					.owl-stage {
						display: -webkit-flex;
						display: -ms-flex;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						align-items: center;
					}
					.owl-item {
						a {
							text-align: center;
						}
						img {
							display: inline-block;float: none;width: auto;
							max-height: 120px;
							@include lg {
								max-height: none;
							}
						}
					}
					.owl-dots {
						margin-top: 30px;
					}
				}
				@include lg {
					&.owl-carousel.owl-carousel-lpDesign {
						display: -webkit-flex;
						display: -ms-flex;
						display: flex;
					}
				}
				li {
					padding: 5px;
					flex: 0 0 100%;
					max-width: 100%;
					/* padding: cs(20,5);
					flex: 0 0 33%;
					max-width: 33%;
					@include sm {
						max-width: 25%;
						flex-basis: 25%;
					}
					@include md {
						max-width: 20%;
						flex-basis: 20%;
					} */
					@include lg {
						max-width: 11.11%;
						flex-basis: 11.11%;
						padding: 20px;
					}
					a {
						display: block;float: left;width: 100%;
						margin: 0;padding: 0;
						text-align: center;
						img {
							display: inline-block;width: auto;height: auto;max-width: 100%;
							margin: 0;
							max-height: 50px;
						}
					}
				}
			}
		}
	}
// content
