// content
.modal {
    z-index: 9999999;
    padding: 0 !important;
    top: 0;

    &-backdrop {
        z-index: 999999;

        &.show {
            opacity: .8;
        }
    }
    /*&-dialog {
        max-width: 100%;
    }*/
    .close {
        color: $b;
        top: 10px;
        right: 10px;
        /*padding: 0;
        margin: 0;*/
        font-size: 15px;
        outline: none;
        opacity: 1;
        padding: 1rem 1rem;
        margin: -1rem 0 -1rem auto;

        :not(.bootbox-close-button) {
            position: absolute;
            transition-property: color;
            transition-duration: .3s;
        }

        .icon {
            display: inline-block;
            transform: rotate(45deg);
        }

        &:hover {
            opacity: 1;
            color: $blue2;
        }
    }

    &-content {
        /* background: none; */
        @include br(30px);
    }

    &-header {
        position: relative;
        border: none;
        font-size: 13px;
        padding: 15px 20px;
        /* background: $w; */
        p {
            display: inline-block;
            width: 100%;
        }
    }

    &-body {
        border: none;
        /* background: $w; */
    }

    &-footer {
        border: none;
    }

    table {
        width: 100%;

        tr {
            td, th {
                background: #ececec;
            }
        }
    }

    form {
        a {
            &.btn {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .row {
            .col {
                padding: 0 5px;
            }
        }
        /* .icon {
				display: inline-block;
				padding: 5px 1rem;margin: 0;
				line-height: 18px;
				text-transform: none;
				background: $g;
				@include br(18px);
			} */
        label {
            font-size: 8px;
            font-weight: $bold;
        }

        input,
        textarea,
        select,
        button {
            background: $g;

            &[type="checkbox"],
            &[type="radio"] {
                & + label {
                    line-height: 18px;

                    &:after {
                        width: 18px;
                        height: 18px;
                        margin-right: 5px;
                        color: $blue2;
                        font-size: 12px;
                        line-height: 18px;
                        background: $g;
                    }
                }
            }
        }
    }

    .opt {
        position: relative;

        > a {
            display: inline-block;
            padding: 5px 1rem;
            margin: 0;
            line-height: 18px;
            text-transform: none;
            text-decoration: none;
            background: $g;
            @include br(18px);
            z-index: 999;

            .icon {
                font-size: 12px;
                line-height: inherit;
            }

            &:hover {
                background: $blue;
                color: $w;
            }
        }

        .optMenu {
            position: absolute;
            top: calc(100% + 0px);
            left: 50%;
            float: left;
            margin: auto;
            padding: 14px 12px;
            list-style: none;
            color: var(--color-text-secondary);
            text-align: center;
            background: $w;
            transform: translateX( -50% );
            will-change: filter;
            filter: drop-shadow(0 0 10px rgba($b,.3));

            @include br(14px);
            z-index: 99999999999;
            // li {
            a {
                display: block;
                float: left;
                width: 100%;
                margin: 0;
                margin-bottom: 4px;
                font-size: 9px;
                font-weight: $bold;
                white-space: nowrap;
                text-decoration: none;

                &:hover {
                    color: $blue2;
                }

                &.active {
                    color: $w;
                    background: $blue;
                    padding: 2px 10px;
                    @include br(4rem);
                }

                &.btn {
                    &:hover {
                        color: $w;
                    }
                }
            }
            // }
        }
    }

    .box {
        padding: 10px 20px;
        /* background: $g;
			@include br(12px); */
    }

    &#video {
        .modal {
            &-body {
                object,
                embed,
                iframe,
                img {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    clear: both;
                }
            }
        }
    }

    & {
        font-size: 10px;
        font-weight: $sbold;

        .wrap {
            overflow: visible;
        }

        h4 {
            font-size: 12px;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        a {
            &.btn {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        p {
            margin: 0;
        }

        .buttons {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
        }
        /* .modal-content {@include br(50rem);} */
        .change {
            display: inline-block;
            position: relative;

            .select {
                text-decoration: none;

                .icon {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    font-size: 4px;
                    line-height: 8px;
                    vertical-align: middle;
                    transform-origin: center;
                }

                &.active {
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }

            ul {
                position: absolute;
                top: 100%;
                left: 50%;
                right: auto;
                display: none;
                float: left;
                width: auto;
                margin: 0;
                padding: 10px;
                background: $w;
                list-style: none;
                @include br(6px);
                will-change: filter;
                filter: drop-shadow(0 0 12px rgba($b,.3));

                &.open {
                    display: block;
                    z-index: 1;
                }

                li {
                    display: inline-block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 3px;
                    font-size: 9px;

                    .icon {
                        display: inline-block;
                        width: 20px;
                        height: 12px;
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &#modal-enter-results {
        z-index: 9999999999;

        #info {
            margin-top: 10px;

            h4 {
                margin: 0;
                font-size: 10px;
                font-weight: $bold;
            }

            p {
                margin-bottom: 20px;
                font-size: 7px;
                font-weight: $medium;
            }
        }

        .confirmBox {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            /* display: none; */
            /* display: block; */
            float: left;
            width: 100%;
            font-size: 10px;
            background: rgba($b,.6);

            > .row {
                margin: 0;
                padding: 30px;
                background: $w;
                @include br(36px);
                z-index: 9;
            }
        }

        form {
            input,
            textarea {
                text-align: center;
                font-size: 11px;
                line-height: 18px;
                color: var(--color-text-secondary);
                font-weight: $bold;
            }
        }
    }

    &#h2hmodal {
        font-size: 10px;

        .img {
            margin-bottom: 10px;

            img {
                overflow: hidden;
                @include br(100%);
            }
        }

        .name {
            margin-bottom: 10px;
            font-size: 11px;
            font-weight: $bold;

            a {
                text-decoration: none;
            }

            img {
                display: inline-block;
                width: 15px;
                height: 11px;
                margin: 0 2px;
                padding: 0;
            }
        }

        .score {
            margin-bottom: 20px;
            font-size: 40px;
            font-weight: $bold;

            .small {
                font-size: 11px;
            }
        }

        .box {
            margin-bottom: 2px;
            /* font-weight: $bold; */
            /* @include br(4rem); */
        }
    }

    &.bootbox {
        .modal-content {
            padding: 20px 10px 10px 10px;
        }

        .modal-header {
            display: block;

            .bootbox-close-button {
                margin-top: -4px !important;
                padding: 0;
            }
        }

        .modal-body {
            .bootbox-close-button {
                margin-top: -25px !important;
            }
        }

        .bootbox-close-button {
            font-size: 26px;
            padding-right: 0px;

            &:hover {
                color: $blue2;
            }
        }
    }

    #modal-form-enter-result {
        label {
            font-size: 12px;
        }

        input[type="text"], input[type="number"] {
            font-size: 16px;
            font-weight: 700 !important;
        }
        //make all buttons the same
        .btn-sm {
            font-size: 16px;
            line-height: 21px;
            font-weight: 700 !important;
        }
    }
}
// content
