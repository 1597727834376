// content
	.clearLpDesgin {
		position: relative;
		padding: 30px 0;
		@include lg {
			padding: 60px 0;
		}
		.img {
			text-align: center;
		}
		img {
			width: auto;height: auto;max-width: 100%;
			margin: 0 auto;padding: 0;margin-bottom: 30px;
			&.fullWindow {
				width: 100vw;max-width: 100vw;
				margin-left: -20%;margin-right: -20%;
			}
			&.size-full {
				display: block;float: left;width: 100%;
			}
			&.alignright {
				float: right;width: 100%;
				@media screen and (min-width: $sm) {width: auto;margin-left: 20px;}
			}
			&.alignleft {
				float: left;width: 100%;
				@media screen and (min-width: $sm) {width: auto;margin-right: 20px;}
			}
		}
		&.e404,
		&.e500 {
			@include lg {
				padding: 80px 0;
			}
			h2 {
				margin-bottom: 10px;font-weight: $xbold;
			}
			.large {
				font-size: 125px;line-height: 1;
			}
			img {
				margin: 30px 0;
			}
		}
		.poweredBy {
			display: block;float: left;width: 100%;
			margin: 0;padding: 10px;
			font-size: 11px;
			clear: both;
			h5 {
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 10px;
			}
			a {
				padding: 0 8px;margin-bottom: 10px;
				img {
					display: inline-block;max-width: 85px;
					margin: 0;padding: 0;
				}
			}
		}
		.new {
			display: block;float: left;width: 100%;
			padding: 40px 15px;margin: 0;
			clear: both;
			p {
				margin-bottom: 10px;
			}
			a {
				display: inline-block;width: auto;float: none;
			}
		}
	}
// content
