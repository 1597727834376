// mixins
	$top: 0;
	$left: 0;
	$blur: 0;
	$spread: 0;
	$color: rgba(0,0,0,0);
	/*
		* 'rem' is a Sass mixin that converts pixel values to rem values for whatever property is passed to it.
		* It returns two lines of code — one of the regular pixel values (for IE), and another with the
		* converted rem values (for everyone else). Special thanks to Chris Epstein (http://chriseppstein.github.com)
		* and Martin Bavio (http://martinbavio.com) for the help and code!
		*
		* Sample input:
		* .element {
		*   @include rem('padding',10px 0 2px 5px);
		* }
		*
		* Sample output:
		* .element {
		*   padding: 10px 0 2px 5px;
		*   padding: 1rem 0 0.2rem 0.5rem;
		* }
		*
	*/

	// Baseline, measured in pixels
	// The value should be the same as the font-size value for the html element
	// If the html element's font-size is set to 62.5% (of the browser's default font-size of 16px),
	// then the variable below would be 10px.
	$baseline_px: 16px;

	@mixin rem($property, $px_values) {
		// Convert the baseline into rems
		$baseline_rem: ($baseline_px / 1rem);
		// Print the first line in pixel values
		#{$property}: $px_values;
		// If there is only one (numeric) value, return the property/value line for it.
		@if type-of($px_values) == 'number' {
			#{$property}: $px_values / $baseline_rem;
		}
		// If there is more than one value, create a list and perform equations on each value
		@else {
			// Create an empty list that we can dump values into
			$rem_values: ();
			@each $value in $px_values {
				// If the value is zero, return 0
				@if $value == 0 {
					$rem_values: append($rem_values, $value);
				}
				// If the value is not zero, convert it from px to rem
				@else {
					$rem_values: append($rem_values, ($value / $baseline_rem) );
				}
			}
			// Return the property and its list of converted values
			#{$property}: $rem_values;
		}
	}
	// @include box-shadow(0, 0, 30px, 0, $b);
	@mixin box-shadow($top, $left, $blur, $spread, $color, $inset:"") {
		-webkit-box-shadow:$top $left $blur $spread $color #{$inset};
		-moz-box-shadow:$top $left $blur $spread $color #{$inset};
		box-shadow:$top $left $blur $spread $color #{$inset};
	}
	@mixin br( $x ) {
		-webkit-border-radius: $x;
		-moz-border-radius: $x;
		border-radius: $x;
	}
	@mixin tr( $what:all, $style:ease, $time:0.5s ) {
		-webkit-transition: $what $style $time;
		-moz-transition: $what $style $time;
		-o-transition: $what $style $time;
		transition: $what $style $time;
	}
	@mixin op( $o:0 ) {
		zoom: 1;
		-webkit-opacity: $o;
		-khtml-opacity: $o;
		-moz-opacity: $o;
		filter:alpha(opacity=($o * 100));
		// filter: progid:DXImageTransform.Microsoft.Alpha(opacity=($o * 100));
		opacity: $o;
	}

	@mixin chartAnimation( $animName, $from, $to ) {
		@-webkit-keyframes #{$animName} {
			0% { stroke-dashoffset: $from; }
			100% { stroke-dashoffset: $to; }
		}
		@-moz-keyframes #{$animName} {
			0% { stroke-dashoffset: $from; }
			100% { stroke-dashoffset: $to; }
		}
		@-ms-keyframes #{$animName} {
			0% { stroke-dashoffset: $from; }
			100% { stroke-dashoffset: $to; }
		}
		@keyframes #{$animName} {
			0% { stroke-dashoffset: $from; }
			100% { stroke-dashoffset: $to; }
		}
	}

	$fontScale: 1;
	$fontScale2: .8;
	@mixin fz($fMax, $fMin, $resMax:$fontResMax, $resMin:$fontResMin) {
		$fMax: $fMax * $fontScale;
		$fMin: $fMin * $fontScale;
		font-size: calc( #{$fMin}px + (#{$fMax} - #{$fMin}) * (100vw - #{$resMin}px) / (#{$resMax} - #{$resMin}) );
	}
	@function cs($fMax, $fMin, $resMax:$fontResMax, $resMin:$fontResMin) {
		@return calc( #{$fMin}px + (#{$fMax} - #{$fMin}) * (100vw - #{$resMin}px) / (#{$resMax} - #{$resMin}) );
	}
	@mixin elipsis($font-size, $line-height, $lines-to-show) {
		display: block;
  		display: -webkit-box;
  		max-height: calc(#{$font-size}px * #{$line-height} * #{$lines-to-show});
  		font-size: #{$font-size}px;
		line-height: #{$line-height};
		-webkit-line-clamp: #{$lines-to-show};
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}


	/*
	**	breakpoints
	*/
	@mixin xs {
	    @media screen and (min-width: $xs) {
	        @content;
	    }
	}
	@mixin sms {
	    @media screen and (min-width: $sms) {
	        @content;
	    }
	}
	@mixin sm {
	    @media screen and (min-width: $sm) {
	        @content;
	    }
	}
	@mixin md {
	    @media screen and (min-width: $md) {
	        @content;
	    }
	}
	@mixin lg {
	    @media screen and (min-width: $lg) {
	        @content;
	    }
	}
	@mixin xl {
	    @media screen and (min-width: $xl) {
	        @content;
	    }
	}
	@mixin xlg {
	    @media screen and (min-width: $xlg) {
	        @content;
	    }
	}
	@mixin max {
	    @media screen and (min-width: $max) {
	        @content;
	    }
	}
// miksins end
