﻿// content
.dropdown {
    width: 100%;
    margin: 0;
    padding: 0;

    .btn-dropdown {
        font-size: 12px;
    }

    .dropdown-toggle {
        position: relative;
        width: 100%;
        padding-left: 8px;
        padding-right: 12px;
        text-align: left;

        &:after {
            content: "\e91a";
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            width: 10px;
            height: 10px;
            margin: auto;
            font-family: $ico;
            font-size: 5px;
            line-height: 10px;
            border: none;
            transform-origin: center center;
            transition-property: transform;
            transition-duration: .3s;
        }

        &[aria-expanded="true"] {            
            &:after {
                transform: rotate(180deg);               
            }
        }

        &:hover {
            background: var(--color-text-secondary);
            color: $w;
        }

        span {
            margin-right: 10px;
        }
    }

    .dropdown-menu {
        right: 0;
        max-width: 100%;
        margin: 0 auto;
        background: $tableOnWhiteBg;
        border: none;        
        will-change: filter;
        filter: drop-shadow(0 0 18px rgba($b, .25));
        border-radius: 0 0 12px 12px;
        z-index: 99999;

        .dropdown-item {
            padding: 5px 1rem;
            margin: 0;
            padding-right: 30px;
            margin-bottom: 5px;
            color: var(--color-text-secondary);
            font-size: 12px;
            font-weight: $bold;
            white-space: nowrap;
            text-transform: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            cursor: pointer;

            &.active,
            &:hover {
                background: $blue;
                color: $w;
            }
        }
    }
}
// content
