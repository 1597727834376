// content
/* #959595 */
	.admin {
		&Panel {
			position: fixed;top: 20%;
			display: block;width: 280px;
			padding: 0;margin: 0;

			transform: translateX(-100%);
			transition-property: transform;
			transition-duration: .5s;
			z-index: 99999999;
			.col {
				padding: 0;margin: 0;
			}
			.wrapper {
				max-height: 80vh;
				padding: 25px;margin: 0;
				background: #fff1e0;
				@include br(0 18px 18px 0);
				overflow-y: auto;
			}
			a {
				margin-bottom: 3px;
				&.btn {
					width: 100%;
					text-align: center;
					&-lg {
						padding: 7px 15px;margin-bottom: 30px;
						font-size: 16px;
					}
					&-md {
						padding: 6px 15px;margin-bottom: 10px;
						font-size: 13px;
					}
					&-sm {
						padding: 2px 5px;margin-bottom: 10px;
						font-size: 11px;
					}
					&-xs {
						padding: 2px 5px;
						font-size: 9px;
					}
				}
			}
			.what {
				padding: 20px 0;
				color: $orange;
				h3 {
					margin: 0;margin-bottom: 14px;padding: 0 10px;
					font-size: 16px;
				}
				ul {
					padding-left: 28px;
					li {
						&.done {
							color: #959595;
							text-decoration: line-through;
						}
					}
				}
			}
			.twoCol {
				column-count: 2;
				column-gap: 5px;
			}
			.adminRibbon {
				position: absolute;top: 30px;right: 0;
				margin: 0;padding: 0px 7px 2px;
				color: $w;font-size: 9px;font-weight: $bold;
				background: $orange;
				text-decoration: none;
				transform: rotate(-90deg) translateY(100%);
				transform-origin: bottom right;
				@include br(0 0 6px 6px);
				@include lg {
					top: 0;
					padding: 3px 20px 6px;
					font-size: 12px;
					@include br(0 0 12px 12px);
				}
			}
			&:hover {
				transform: translateX(0);
			}
		}
	}
// content
