// animation
	// -webkit-animation: moveToRight 1s;
	// -moz-animation: moveToRight 1s;
	// animation: moveToRight 1s;

	/* animation-name
	animation-duration
	animation-delay
	animation-iteration-count
	animation-direction
	animation-timing-function
	animation-fill-mode
	animation */

	// -webkit-animation: anim-moema-2 0.3s 0.3s forwards;
	// animation: anim-moema-2 0.3s 0.3s forwards;

	@keyframes bounceIn {
	    /* 0%, 20%, 40%, 60%, 80%, to { */
	    0%, 1%, 2%, 3%, 4%, 5% {
	        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	    }

	    0% {
	        opacity: 0;
	        -webkit-transform: scale3d(.3, .3, .3);
	        transform: scale3d(.3, .3, .3)
	    }

	    1% {
	        -webkit-transform: scale3d(1.1, 1.1, 1.1);
	        transform: scale3d(1.1, 1.1, 1.1)
	    }

	    2% {
	        -webkit-transform: scale3d(.9, .9, .9);
	        transform: scale3d(.9, .9, .9)
	    }

	    3% {
	        opacity: 1;
	        -webkit-transform: scale3d(1.03, 1.03, 1.03);
	        transform: scale3d(1.03, 1.03, 1.03)
	    }

	    4% {
	        -webkit-transform: scale3d(.97, .97, .97);
	        transform: scale3d(.97, .97, .97)
	    }

	    5% {
	        opacity: 1;
			-webkit-transform: scaleX(1);
			transform: scaleX(1)
		}
	}

	/* .bounceIn {
	    -webkit-animation-duration: .75s;
	    animation-duration: .75s;
	    -webkit-animation-name: bounceIn;
	    animation-name: bounceIn
	} */
// animation end
