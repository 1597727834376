// content
.boxLpDesign {
    padding: 30px cs(100,30) 20px;
    margin-bottom: 30px;
    background: $boxBg;
    border-radius: 30px;

    @include xlg {
        padding: 30px 100px 20px;
    }

    &.p-small {
        padding: 20px;

        @include xlg {
            padding: 45px 40px 30px;
        }
    }

    form {
        text-align: center;

        input[type=submit],
        button {
           
            display: inline-block;
            width: auto;
            float: none;
            
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            
            font-weight: 700;
            background: #37d076;

            &.blue2 {
                background-color: #00b9ff;
                border-color: #00b9ff;
            }
        }

        input,
        select,
        textarea,
        button {
            padding: 10px 15px;
            text-align: center;

            &[type="submit"],
            &.blue2 {
                padding: 4px 25px;

                @include lg {
                    padding: 4px 40px;
                }
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &.goBack {
            padding: 0 10px;
            font-size: 14px;
            font-weight: $medium;
        }
    }

    .boxLpDesign {
        padding: 30px 25px;
        margin-bottom: 15px;
        background: $boxBoxBg;
        border-radius: 20px;

        &.p-small {
            padding: 20px 15px;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}
// content
