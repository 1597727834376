// content
.contact {
	padding: 30px 0;
	background: $contactBg;
	background-image: none;
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: contain;
	color: $w;

	@include lg {
		padding: 60px 0 40px;
		background-image: url('https://cdn.rankedin.com/images/static/contactBg.jpg');
	}

	h2 {
		margin-bottom: 10px;
	}

	form {
		> .row {
			> .col {
				padding: 0 18px;
				margin-bottom: 20px;
				/* @include lg {
						margin-bottom: 40px;
					} */
			}
		}

		input,
		button,
		select,
		textarea {
			border: none;

			&:not([type="submit"]) {
				padding: 15px 0;
				background: none;
				border: none;
				border-bottom: 2px solid $w;
				border-radius: 0;

				&[placeholder] {
					color: $w !important;
				}

				&::-webkit-input-placeholder {
					font-weight: $normal;
					color: $w;
					opacity: 1;
					font-style: normal;
				}

				&:-moz-placeholder {
					/* Firefox 18- */
					font-weight: $normal;
					color: $w;
					font-style: normal;
				}

				&::-moz-placeholder {
					/* Firefox 19+ */
					font-weight: $normal;
					color: $w;
					font-style: normal;
				}

				&:-ms-input-placeholder {
					font-weight: $normal;
					color: $w;
					font-style: normal;
				}
			}

			&[type="submit"] {
				padding: 4px 35px;
				font-size: 14px;
				line-height: 20px;
				color: $w;
				font-weight: $bold;
				text-transform: none;
				background: $blue2;
				border: 2px solid $blue2;
				@include br(50px);

				&:hover {
					background: $green;
					border-color: $green;
				}
			}
		}

		textarea {
			height: 52px;
		}

		.dropdown {
			text-align: left;

			@include lg {
				text-align: center;
			}

			.dropdown-toggle {
				color: $w;
				outline: none;
				text-align: left;

				@include lg {
					text-align: center;
				}

				&:hover {
					background: none;
				}

				&::after {
					@include lg {
						position: initial;
						width: 20px;
					}
				}
			}

			.dropdown-menu {
				padding: 13px 0;
				background: $w;
				border: none;
				border-radius: 14px;
			}

			.dropdown-item {
				padding: 5px;
				margin: 0;
				font-size: 14px;
				line-height: 20px;
				color: $blue_lpDesign;
				font-weight: $bold;
				text-align: center;

				&.active,
				&:hover {
					background: $testimonialsBoxBg;
					color: $w;
				}
			}
		}
	}
}
// content
