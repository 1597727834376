// content
.notyfication {
    margin: 0;
    padding: 0 8px;
    margin-bottom: 20px;
    font-size: 8px;

    @include lg {
        padding: 0 15px;
        font-size: 9px;
    }

    .alert {
        margin-bottom: 4px;
        padding: 5px 10px;
        font-size: 10px;
        color: var(--color-text-secondary);
        font-weight: $medium;
        background: #e0e2eb;
        border-radius: 4rem;
        border: none;

        p {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            * {
                flex: 0 0 auto;

                &.float-right { /* align-self: flex-end; */
                    justify-self: end;
                }
            }
        }

        a {
            &:not(.btn) {
                text-decoration: underline;
            }

            &.btn {
                margin-left: auto;

                &:hover {
                    color: $w;
                    background: $bluel;
                    border-color: $bluel;
                }
            }
        }

        .icon {
            display: inline-block;
            padding: 0 10px;
            vertical-align: middle;

            &-bell {
                font-size: 14px;
            }

            &-check {
                font-size: 14px;
            }

            &-warning {
                font-size: 14px;
            }
        }

        .date {
            display: inline-block;
            padding: 2px 10px;
            font-size: 11px;
            color: $w;
            background: $gd;
            @include br(4rem);
        }

        .tn {
            display: inline-block;
            padding: 0 5px;
        }

        .message {
            flex-grow: 1;
            flex-shrink: 1;
            width: auto;
            max-width: 100%;
            display: inline-block;
            padding: 5px;
            text-align: center;
            order: 12;

            @include lg {
                padding: 0 5px;
                flex-grow: 0;
                max-width: 75%;
                order: initial;
                text-align: left;
            }
        }

        &-primary {
            border-color: rgba($blue,.2);
            background: rgba($blue,.2);

            .btn,
            .date {
                background: rgba($blue,1);
                border-color: rgba($blue,1);
            }
        }

        &-success {
            border-color: rgba($green,.2);
            background: rgba($green,.2);

            .btn,
            .date {
                background: rgba($green,1);
                border-color: rgba($green,1);
            }
        }

        &-info {
            border-color: rgba($blue2,.2);
            background: rgba($blue2,.2);

            .btn,
            .date {
                background: rgba($blue2,1);
                border-color: rgba($blue2,1);
            }
        }

        &-warning {
            border-color: rgba($orange,.2);
            background: rgba($orange,.2);

            .btn,
            .date {
                background: rgba($orange,1);
                border-color: rgba($orange,1);
            }
        }

        &-danger {
            border-color: rgba($red,.2);
            background: rgba($red,.2);

            .btn,
            .date {
                background: rgba($red,1);
                border-color: rgba($red,1);
            }
        }

        &-light {
            border-color: rgba($blue,.2);
            background: #f3f3f3;

            .btn,
            .date {
                background: rgba($blue,1);
                border-color: rgba($blue,1);
            }
        }
    }
}
// content
