// content
.content {
	padding: 0 8px;
	overflow-y: auto;

	@include lg {
		padding: 0 20px;
	}

	p {
		a {
			&:not(.btn) {
				text-decoration: underline;
			}
		}
	}

	.box {
		/* padding: 20px 10px;margin-bottom: 25px;
			background: $gl;
			background: #f3f3f3;
			@include br(50px);
			overflow-y: auto;
			@include lg {
				padding: 35px 25px;
			} */
		> p {
			padding: 0 0px;

			@include lg {
				padding: 0 20px;
			}
		}

		

		table {
			width: auto;

			tr {
				td, th {
					/* border-color: #f3f3f3; */
					/* img {
							margin: 0;
						}
						a {
							white-space: nowrap;
							&.btn {
								margin: 2px 0;
							}
						} */
				}

				th {
					.icon {
						display: inline-block;

						&-v {
							font-size: 5px;
						}
					}
				}
			}
		}

		&Tab {
			width: 100%;
			margin: 0;
			padding: 0;
			color: var(--color-text-secondary);

			&Title {
				padding: 0 20px;

				@include lg {
					padding: 0 40px;
				}

				a {
					display: inline-block;
					margin: 0;
					padding: 3px 15px;
					font-size: 14px;
					font-weight: $normal;
					text-decoration: none;
					@include br(8px 8px 0 0);

					&[aria-expanded="true"] {
						font-weight: $bold;
						background: $gl;
					}
				}
			}

			&Cont {
				padding: 20px 15px;
				margin-bottom: 25px;
				background: $gl;
				@include br(28px);
				overflow-x: auto;

				@include lg {
					/* padding: 35px 25px; */
				}

				table {
					width: auto;

					tr {
						td, th {
							border-color: $gl;
							font-weight: $bold;

							img {
								margin: 0;
							}

							a {
								display: inline-block;
								white-space: nowrap;
								vertical-align: middle;

								&.btn {
									margin: 2px;
								}
							}
						}

						td {
							background: $tableBg;
						}

						th {
							.icon {
								display: inline-block;

								&-v {
									font-size: 5px;
								}
							}
						}
					}
				}

				.no-events {
					img {
						display: block;
						margin-top: 3em;
						margin-bottom: 3em;
						margin-left:auto;
						margin-right:auto;
					}

					a {
						margin-bottom: 3em;
					}

					p {
						margin-bottom: 3em;
					}
				}
			}

			&.teamTabs {
				.boxTabTitle {
					position: relative;
					margin-bottom: -17px;
					display: -webkit-flex;
					display: -ms-flex;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;

					@include lg {
						flex-wrap: nowrap;
					}

					a {
						flex: 1 1 30%;
						padding: 3px 7px;
						margin: 2px 3px;
						font-size: 13px;
						font-weight: $bold;
						text-align: center;
						background: $g;
						@include br(50px);

						@include lg {
							padding: 6px 15px;
						}

						&[aria-expanded="true"] {
							background: $blue;
							color: $w;
						}
					}
				}

				.boxTabCont {
					padding-top: 35px;

					table {
						width: 100%;

						tr {
							td, th {
								font-size: 10px;
								font-weight: $sbold;

								a {
									&.btn {
										font-size: 8px;
										padding: 0px 3px;
									}
								}

								.flag {
									display: inline-block;
									width: 19px;
									height: 11px;
									padding-right: 5px;

									img {
										width: 14px;
										height: 11px;
									}
								}
							}

							th {
								font-weight: $bold;
							}
						}
					}
				}
			}
		}

		&.highestRankingJump {
			padding: 15px 10px;
			font-weight: $bold;

			.no-pow {
				.img-avatar {
					max-height: 100px;
					opacity:0.4;
				}

				.big {
					color: #e4e4e4;
				}

				.small {
					color: #e4e4e4;
				}
			}

			@include lg {
				padding: 15px 35px;
			}

			a {
				display: inline-block;
				margin: 0;
				padding: 0;
				text-decoration: none;
			}

			img {
				margin: 0;
				padding: 0;
				max-height:88px;
			}

			p {
				margin: 0;
			}

			.img {
				padding: 0;
				@include br(100%);
				overflow: hidden;
				/* max-width: 30px; */
				max-width: 90px;

				@include lg {
					max-width: 100%;
				}
			}

			.small {
				font-size: 8px;
				font-weight: $bold;

				@include lg {
					font-size: 13px;
				}
			}

			.big {
				/* font-size: 22px; */
				font-size: 30px;
				line-height: 1;

				@include lg {
					/* font-size: 42px; */
					font-size: 38px;
				}
			}

			.flag {
				text-align: center;

				img {
					width: cs(48,30);

					@include lg {
						width: 48px;
					}
				}
			}

			.name {
				padding: 0;
				text-transform: uppercase;
				font-size: 15px;
				font-weight: $xbold;

				@include lg {
					font-size: 17px;
				}
			}

			.rank {
				/* font-size: 22px; */
				font-size: 30px;
				font-weight: $xbold;

				@include lg {
					/* font-size: 42px; */
					font-size: 38px;
				}

				.icon {
					display: inline-block;
					padding: 0;
					font-size: 16px;
					vertical-align: middle;
				}

				&.inc {
					.icon {
						color: $green;
						transform: rotate(-90deg);
					}
				}

				&.dec {
					.icon {
						color: $red;
						transform: rotate(90deg);
					}
				}
			}

			.curRank {
				font-weight: $xbold;
				text-align: center;
			}

			.points {
				font-weight: $xbold;
				text-align: center;
			}
		}

		.previousRank {
			padding: 10px 0;
			font-size: 17px;
			font-weight: $bold;

			.col {
				padding: 5px;

				&:nth-of-type(2) {
					p {
						background: $bluel;
					}
				}

				&:nth-of-type(3) {
					p {
						background: $blue2;
					}
				}

				&:nth-of-type(4) {
					p {
						background: $green;
					}
				}
			}

			p {
				display: -webkit-flex;
				display: -ms-flex;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0;
				padding: 20px 10px;
				padding-bottom: 25px;
				color: $w;
				background: $blue;
				@include br(30px);

				> span {
					flex: 0 0 100%;
					width: 100%;
					max-width: 100%;
					padding: 0 5px;
				}
			}

			.bigger {
				display: inline-block;
				float: left;
				padding: 0;
				margin: 0;
				padding-right: 5px;
				font-size: 32px;
				vertical-align: middle;
			}
		}
	}

	.sectionOtpions {		
		font-size: 12px;

		a {
			display: inline-block;
			margin-right: 10px;
			text-decoration: none;
		}
	}

	.logos {
		padding: 0 cs(10,0);
		margin-bottom: 25px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;

		@include lg {
			padding: 0 30px;
		}

		a {
			flex: 0 1 auto;
			display: inline-block;
			width: auto;
			max-width: 100%;
			padding: 0 10px;
			margin: 0;
		}

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			margin: 0;
			padding: 0 10px;
		}
	}

	.share {
		padding: 0 20px;
		font-size: 8px;
		font-weight: $normal;

		p {
			a {
				text-decoration: none;
			}
		}
	}

	.h2hmodal {
		margin-bottom: 37px;
		color: $w;
		font-size: 7px;
		font-weight: $bold;

		&Single {
			padding: 0 3px;
			margin-bottom: 17px;

			.wrapper {
				padding: 0 4px;
				background: $blue;
				@include br(12px);
			}

			p {
				margin: 0;
			}

			h4 {
				margin: 0;
			}

			[class^="col-"],
			[class*=" col-"] {
				padding: 0;
			}

			.col {
				padding: 0;
			}

			&Top {
				padding: 6px 10px;

				.icon {
					font-size: 14px;
				}

				h4 {
					font-size: 13px;
				}

				a {
					text-decoration: none;
				}
			}

			&Result {
				padding: 0;
				color: var(--color-text-secondary);
				background: $w;
				@include br(12px);

				> [class^="col-"],
				> [class*=" col-"] {
					padding: 6px 10px 3px;
				}

				p {
					&:empty {
						&:after {
							content: '-';
							display: inline-block;
							padding: 0 5px;
						}
					}

					&.red {
						display: inline-block;
						padding: 3px 5px;
						background: $red;
						color: $w;
						text-align: center;
						@include br(4em);
					}
				}

				table {
					width: 100%;
					margin: 0;
					padding: 0;
					background: none;

					tr {
						td, th {
							margin: 0;
							padding: 0 1px;
							background: none;
							text-align: right;
							border: none;
							@include br(0);

							&.arrow {
								position: relative;

								&:after {
									content: "\e934";
									position: absolute;
									top: 0;
									bottom: 0;
									right: calc(100% + 2px);
									display: block;
									width: 6px;
									height: 8px;
									padding: 0;
									margin: auto;
									font-family: $ico;
									color: $red;
									font-size: 6px;
									line-height: 1;
								}
							}

							&.red {
								background: $red;
								color: $w;
								text-align: center;
							}

							&:empty {
								&:after {
									content: '-';
									display: inline-block;
								}
							}

							&:first-of-type {
								text-align: left;
								padding-left: 0;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;

								> * {
									display: inline-block;
									/* max-width: 50%; */
								}

								&:empty {
									text-align: right;
								}
							}

							&:last-of-type {
								padding-right: 0;

								&:empty {
									text-align: left;
								}
							}
						}

						&:first-of-type {
							.red {
								@include br(6px 6px 0 0);
							}
						}

						&:last-of-type {
							.red {
								@include br(0 0 6px 6px);
							}
						}
					}
				}

				.ssrTop {
					margin-bottom: 10px;
				}

				.ssrMid {
					margin-bottom: 7px;
					font-weight: $normal;

					@include lg {
						font-size: 9px;
					}

					table {
						tr {
							td, th {
								font-size: cs(8,7);

								@include lg {
									font-size: 8px;
								}
							}
						}
					}

					.ssrMidGrid {
						width: 100%;
						margin: 0;
						padding: 0;
						display: grid;
						/* grid-template-rows: auto; */
						grid-template-rows: auto;
						grid-template-columns: 1fr auto;
						/* grid-template-columns: auto; */
						/* grid-template-areas:
								"name scores"
								"name scores"; */
						grid-column-gap: 2px;
						grid-row-gap: 0px;
						font-size: cs(10,7);

						@include lg {
							font-size: 10px;
						}

						&.serving {
							position: relative;
							overflow: visible;

							&:before {
								content: '';
								position: absolute;
								top: auto;
								right: calc(100% + 1px);
								bottom: auto;
								display: block;
								width: 7px;
								height: 8px;
								margin: auto;
								padding: 0;
								border-left: 6px solid $red;
								border-top: 4px solid transparent;
								border-bottom: 4px solid transparent;
							}

							&-top {
								&:before {
									top: 2px;
								}
							}

							&-bot {
								&:before {
									bottom: 4px;
								}
							}
						}

						.name {
							/* grid-area: name; */
							/* font-size: 10px; */
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;

							&:empty {
								/* &:after {
										content: '-';
										display: inline-block;
										text-align: right;
									} */
								text-align: center;

								&:after {
									content: '- -';
									display: inline-block;
									text-align: center;
								}
							}
						}

						.scores {
							/* grid-area: scores; */
							display: flex;
							justify-content: flex-end;
							min-width: 60%;

							span {
								width: 17px;
								flex: 0 0 17px;
								max-width: 17px;
								padding: 0 2px;
								text-align: center;

								&.red {
									background: $red;
									color: $w;
								}
							}

							&:nth-of-type(2) {
								span {
									&.red {
										@include br(6px 6px 0 0);
									}
								}
							}

							&:nth-of-type(4) {
								span {
									&.red {
										@include br(0 0 6px 6px);
									}
								}
							}
						}
					}
				}

				.ssrBot {
					p {
						padding: 3px 0;

						&.red {
							padding: 3px 5px;
						}
					}
				}

				.grid {
					/* autoprefixer grid: autoplace */
					display: grid;
					width: 100%;
					grid-template-columns: auto 1fr;
					grid-template-rows: auto;
					align-items: center;

					> * {
						align-self: center;
					}

					.team {
						padding-right: 5px;

						&:empty {
							text-align: center;

							+ .score {
								&:empty {
									text-align: center;
									justify-content: center;

									&:after {
										content: '- -';
										display: inline-block;
										padding: 0 5px;
									}
								}
							}
						}
					}

					.score {
						text-align: center;
						text-align: right;

						.col {
							&:last-of-type {
								padding-right: 0;
							}
						}
					}
				}
			}

			&Next {
				padding: 8px 10px;

				p {
					&:empty {
						&:after {
							content: '-';
							display: inline-block;
							padding: 0 5px;
						}
					}
				}

				.time {
					@include lg {
						font-size: 8px;
					}
				}

				.reverse {
					@include lg {
						font-size: 9px;
					}

					.text-left {
						p {
							&:empty {
								text-align: right;
							}
						}
					}

					.text-right {
						p {
							&:empty {
								text-align: left;
							}
						}
					}
				}

				.vs {
					padding: 0 3px;
				}

				.mediumFont {
					font-weight: $medium;
				}

				.sBoldFont {
					font-weight: $sbold;
				}
			}
		}
	}
}
// content
