// content
	.testimonials {
		padding: 30px 0;
		background: $testimonialsBg;
		@include lg {
			padding: 60px 0 40px;
		}
		.img {
			margin: 0;padding: 0;
			color: $w;
			img {
				display: inline-block;width: auto;height: auto;max-width: 100%;
				margin-bottom: 30px;
				border: 2px solid $w;
				border-radius: 100%;
			}
		}
		.name {
			margin-bottom: 10px;
			font-size: 18px;
		}
		.boxLpDesign {
			padding: 20px 30px;
			color: $w;
			background: $testimonialsBoxBg;
			text-align: center;
			@include lg {
				padding: 40px 60px 30px;
				text-align: left;
			}
		}
		h4 {
			font-size: 20px;
		}
		p {
			font-size: 14px;
		}
		.www {
			font-size: 10px;font-weight: $xbold;
			text-transform: uppercase;
			@include lg {font-size: 12px;}
		}
	}
// content
