// content
.timetable {
	display: block;
	width: 100%;
	max-width: 100%;
	max-height: 75vh;
	margin: 0;
	padding: 0;
	/*overflow: auto;*/
	form {
		input {
			&[type="checkbox"] {
				& + label {
					&:after {
						font-size: 7px;
					}
				}
			}
		}
	}

	table {
		text-align: left;

		tr {
			td, th {
				padding: 6px 7px;
				font-size: 8px;
				font-weight: $medium;
				border: 2px solid $gl;
				@include br(10px);

				&:first-of-type {
					text-align: left;
				}

				[class^="col"],
				[class*=" col"] {
					padding: 0;
				}

				.team {
					width: auto;
					max-width: calc(100% - 25px);
					/* max-width: 80%; */
					margin-bottom: 10px;
					font-size: 9px;
					font-weight: $bold;

					&.featured {
						color: #98ff00;
					}

					.elipsis {
						position: relative;
						display: inline-block;
						width: 100%;
						margin: 0;
						padding: 0;
						padding-right: 30px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.score {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						display: inline-block;
						float: right;
					}

					.alwaysBot {
						position: absolute;
						bottom: 20px;
						left: 7px;
						right: 7px;
						transform: translateY(100%);
					}
				}

				.subType {
					display: inline-block;
					float: left;
					clear: both;
					margin-top: 12px;
					padding: 0px 2px;
					background: $w;
					color: var(--color-text-secondary);
					@include br(4rem);
				}

				*:not(.subType) {
					& + .subType {
						margin-top: 0px;
					}
				}
			}

			th {
				text-align: left;
				font-size: 11px;
				font-weight: $bold;

				@include lg {
					text-align: center;
				}
			}

			td {
				text-align: left;

				&[class^="cl"],
				&[class*=" cl"] {
					min-width: 130px;
					color: $w;

					&:last-of-type {
						text-align: left;
					}

					form {
						margin-bottom: 8px;
					}
				}

				&.cl00 {
					background: $cl00;

					.subType {
						color: $cl00;
					}
				}

				&.cl01 {
					background: $cl01;

					.subType {
						color: $cl01;
					}
				}

				&.cl02 {
					background: $cl02;

					.subType {
						color: $cl02;
					}
				}

				&.cl03 {
					background: $cl03;

					.subType {
						color: $cl03;
					}
				}

				&.cl04 {
					background: $cl04;

					.subType {
						color: $cl04;
					}
				}

				&.cl05 {
					background: $cl05;

					.subType {
						color: $cl05;
					}
				}

				&.cl06 {
					background: $cl06;

					.subType {
						color: $cl06;
					}
				}

				&.cl07 {
					background: $cl07;

					.subType {
						color: $cl07;
					}
				}

				&.cl08 {
					background: $cl08;

					.subType {
						color: $cl08;
					}
				}

				&.cl09 {
					background: $cl09;

					.subType {
						color: $cl09;
					}
				}

				&.cl10 {
					background: $cl10;

					.subType {
						color: $cl10;
					}
				}

				&.cl11 {
					background: $cl11;

					.subType {
						color: $cl11;
					}
				}

				&.cl12 {
					background: $cl12;

					.subType {
						color: $cl12;
					}
				}

				&.cl13 {
					background: $cl13;

					.subType {
						color: $cl13;
					}
				}

				&.cl14 {
					background: $cl14;

					.subType {
						color: $cl14;
					}
				}

				&.cl15 {
					background: $cl15;

					.subType {
						color: $cl15;
					}
				}

				&.cl16 {
					background: $cl16;

					.subType {
						color: $cl16;
					}
				}

				&.cl17 {
					background: $cl17;

					.subType {
						color: $cl17;
					}
				}
			}
		}
	}
}

.cl00 {background: $cl00;}
.cl01 {background: $cl01;}
.cl02 {background: $cl02;}
.cl03 {background: $cl03;}
.cl04 {background: $cl04;}
.cl05 {background: $cl05;}
.cl06 {background: $cl06;}
.cl07 {background: $cl07;}
.cl08 {background: $cl08;}
.cl09 {background: $cl09;}
.cl10 {background: $cl10;}
.cl11 {background: $cl11;}
.cl12 {background: $cl12;}
.cl13 {background: $cl13;}
.cl14 {background: $cl14;}
.cl15 {background: $cl15;}
.cl16 {background: $cl16;}
.cl17 {background: $cl17;}
.cl18 {background: $cl18;}
.cl19 {background: $cl19;}
.cl20 {background: $cl20;}
.cl21 {background: $cl21;}
.cl22 {background: $cl22;}
// content
