// content
.team {
    &Cont {
        .content {
            overflow-y: visible;

            .box {
                overflow-y: visible;
            }

            > .row {
                > .col {
                    padding: 0;

                    @include lg {
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}

.team,
.event {
    &Banner {
        padding: 40px 0 23px 0;
        background: $bluel;
        color: $w;

        @media screen and (max-width: 1024px) {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        @include lg {
            padding-top: 44px;
        }

        .wrap {
            overflow: visible;
        }

        .cont {
            @media screen and (max-width: 1024px) {
                padding: 0;
            }
        }

        .title {
            text-align: center;
            font-weight: $sbold;
            padding: 0 20px 10px 20px;

            @include lg {
                text-align: left;
                padding: 0 20px;
            }

            h1, h2 {
                margin-bottom: 20px;
                font-weight: $xbold;
                font-size: 32px;

                @include lg {
                    font-size: 36px;
                }
            }

            .type {
                display: inline-block;
                margin: 0;
                padding: 0 8px;
                font-size: 10px;
                line-height: 16px;
                font-weight: $medium;
                vertical-align: middle;
                background: $blue2;
                @include br(8px);
            }

            img {
                display: inline-block;
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
                padding-left: 0;
                vertical-align: middle;
                line-height: 0;
            }

            .info {
                font-size: 12px;
                font-weight: 400;


                & > p {
                    justify-content: space-between;
                    font-size: 16px;

                    @include sms {
                        justify-content: center;
                        font-size: 14px;

                        & > * {
                            padding-right: 0.5rem;
                        }
                    }
                }

                span {
                    display: inline-block;

                    &.icon {
                        &-pin {
                            font-size: 20px;
                            vertical-align: middle;
                        }
                    }
                }
            }

            .more {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin: 0;
                padding: 0;
                color: $w;
                line-height: 0;
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                background: $blue;

                @include br(100%);

                &:hover {
                    background: $blue2;
                }
            }

            .challenge {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin: 0;
                padding: 0;
                color: var(--color-text-secondary);
                font-size: 18px;
                line-height: 1;
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                /* background: $w; */
                @include br(100%);
                /*			.icon {
					position: relative;

					&:before {
						position: relative;
						z-index: 9;
					}

					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						display: block;
						width: 13px;
						height: 13px;
						margin: auto;
						padding: 0;
						background: $w;
						@include br(100%);
						z-index: 0;
					}
				}*/
            }

            .buttons {
                margin-bottom: 10px;
            }

            .stats {
                margin-bottom: 15px;

                @include lg {
                    margin-bottom: 0;
                }

                &Box {
                    display: inline-block;
                    flex-basis: 100%;
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    font-size: 8px;
                    background: $blue;
                    @include br(50px);

                    @include sm {
                        flex-basis: 0;
                        margin: 0;
                    }

                    p {
                        display: -webkit-flex;
                        display: -ms-flex;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        margin: 0;
                        padding: 0;

                        > span {
                            padding: 0 5px;
                            display: -webkit-flex;
                            display: -ms-flex;
                            display: flex;
                            flex-wrap: nowrap;
                            align-items: center;
                        }
                    }

                    .bigger {
                        display: inline-block;
                        float: left;
                        padding: 0;
                        margin: 0;
                        padding-right: 5px;
                        font-size: 19px;
                        vertical-align: middle;
                    }
                }
            }

            .options {
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                flex-wrap: nowrap;

                a {
                    padding: 10px 20px;
                }
                /*   .btn {
                    border: none;
                    @include br(50px 0 0 50px);

                    &:hover {
                        color: $w;
                        background: $blue2;
                    }
                }*/

                .opt {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 25px;
                    flex-basis: 25px;
                    max-width: 25px;
                    padding: 0 5px;

                    > a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        /* display: inline-block; */
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 25px;
                        flex-basis: 25px;
                        max-width: 25px;
                        padding: 0 5px;
                        font-size: 10px;
                        color: $w;
                        text-align: right;
                        background: darken($green,5%);
                        @include br(0 50px 50px 0);

                        .icon {
                            font-size: 18px;
                        }

                        &:hover {
                            color: $w;
                            background: $blue2;
                        }
                    }

                    .optMenu {
                        position: absolute;
                        top: calc(100% + 4px);
                        right: 0px;
                        display: none;
                        float: left;
                        width: 110px;
                        margin: 0 auto;
                        padding: 13px;
                        list-style: none;
                        color: var(--color-text-secondary);
                        text-align: center;
                        background: $w;
                        /* transform: translateX( calc(50% - 7px) ); */
                        @include br(14px);
                        z-index: 99999999999;
                        will-change: filter;
                        filter: drop-shadow(0 0 10px rgba($b,.3));

                        &.open {
                            display: block;
                        }

                        li {
                            a {
                                display: block;
                                float: left;
                                width: 100%;
                                margin: 0;
                                margin-bottom: 4px;
                                padding: 8px 5px 6px;
                                font-size: 10px;
                                font-weight: $bold;
                                white-space: nowrap;
                                background: $g;
                                text-decoration: none;

                                @include br(4rem);

                                &:hover {
                                    background: $bluel !important;
                                    color: $w;
                                }
                            }
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 100%;
                            left: auto;
                            right: 10px;
                            display: block;
                            width: 16px;
                            margin: 0 auto;
                            padding: 0;
                            background: none;
                            border-bottom: 14px solid $w;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                        }
                    }
                }
            }
        }

        &Logo {
            max-width: 70px;
            padding: 5px 0;

            img {
                display: inline-block;
                width: auto;
                height: auto;
                max-width: 100%;
                margin: 0;
                padding: 0;
                border-radius: 5px;
            }

            & + .title {
                @include lg {
                    padding-left: 10px;
                }
            }
        }

        .cont {
        }

        .contactBox {
            width: auto;
            max-width: 100%;
            padding: 7px 14px;
            color: $w;
            font-size: 10px;
            font-weight: $bold;
            background: #5e70b3;
            @include br(50px);

            .col-12,
            .col {
                margin: 5px 0;
                padding: 0 10px;
            }

            p {
                display: inline-block;
                line-height: 1;
                margin: 0;
                white-space: nowrap;
            }

            a {
                position: relative;
                display: inline-block;
                width: auto;
                text-align: center;
                vertical-align: middle;
            }

            .absolute {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                display: inline-block;
                width: auto;
                margin: auto;
                padding: 0;
                text-align: center;
            }

            .icon {
                display: inline-block;
                padding-right: 5px;
                font-size: 20px;
                line-height: 1;
                vertical-align: middle;
            }

            .adminSlider {
                position: relative;

                p {
                    a {
                        display: block;
                        max-width: calc(100% - 30px);
                    }
                }

                .owl-dots {
                    padding-left: 30px;
                }
            }
        }

        .joinBox {
            background: $blue;
            padding: 10px;
            font-size: 10px;

            @media screen and (max-width: 1024px) {
                width: 100%;
                border-radius: 0 !important;
                //when wrapped add a gap between rows
                .row {
                    row-gap: 10px;
                }
            }

            & + .joinBox {
                margin-top: 10px;
            }

            @include sm {
                padding: 16px 30px;
            }

            @include xlg {
                max-width: 90%;
            }

            .sum,
            .join {
                padding: 0 10px;

                @include lg {
                    padding: 0 15px;
                }
            }

            .col {
                padding: 0;
            }

            a {
                &.btn {
                    /* font-size: 10px; */
                    padding: 7px 13px;

                    @include lg {
                        /* padding: 10px 30px; */
                        /* font-size: 1rem; */
                    }
                }
            }

            p {
                display: inline-block;
                margin: 0;
                font-weight: $bold;
            }

            .big {
                font-size: 10px;
                font-weight: $xbold;
                white-space: nowrap;

                @include sm {
                    font-size: 13px;
                }
            }

            .sum {
                vertical-align: middle;

                .count {
                    display: block;
                    float: left;
                    width: auto;
                    font-size: 22px;
                    padding-right: 5px;
                    line-height: 1.2;

                    @include sm {
                        float: left;
                        font-size: 22px;
                        line-height: .8;
                    }

                    @include lg {
                        font-size: 33px;
                    }
                }
            }
        }
    }

    &Lineup {
        .btn-dropdown {
            padding: 2px 30px;
        }
    }

    &Top {
        margin-bottom: 10px;

        .col {
            padding: 0;
        }

        .btn {
            padding: 2px 25px 2px 10px;
            font-size: 12px;
            line-height: 16px;

            @include lg {
                padding: 2px 30px;
            }

            &-light {
                border-width: 2px;
            }
        }

        form {
            > .row {
                > .col {
                    margin: 5px 0;

                    & + .col {
                        padding-left: 10px;
                    }
                }
            }

            select {
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 10px;
                font-weight: $sbold;
                border: 2px solid $blue;
                color: var(--color-text-secondary);

                &[placeholder] {
                    color: var(--color-text-secondary);
                }

                &::-webkit-input-placeholder {
                    color: var(--color-text-secondary);
                }

                &:-moz-placeholder {
                    color: var(--color-text-secondary);
                }

                &::-moz-placeholder {
                    color: var(--color-text-secondary);
                }

                &:-ms-input-placeholder {
                    color: var(--color-text-secondary);
                }
            }

            .classes {
                & + .selectize-control {
                    .selectize-dropdown {
                        .option {
                            &[data-value] {
                                color: $w;
                            }

                            &[data-value="01"] {
                                background: $cl01;
                            }

                            &[data-value="02"] {
                                background: $cl02;
                            }

                            &[data-value="03"] {
                                background: $cl03;
                            }

                            &[data-value="04"] {
                                background: $cl04;
                            }

                            &[data-value="05"] {
                                background: $cl05;
                            }

                            &[data-value="06"] {
                                background: $cl06;
                            }

                            &[data-value="07"] {
                                background: $cl07;
                            }

                            &[data-value="08"] {
                                background: $cl08;
                            }

                            &[data-value="09"] {
                                background: $cl09;
                            }

                            &[data-value="10"] {
                                background: $cl10;
                            }

                            &[data-value="11"] {
                                background: $cl11;
                            }

                            &[data-value="12"] {
                                background: $cl12;
                            }

                            &[data-value="13"] {
                                background: $cl13;
                            }

                            &[data-value="14"] {
                                background: $cl14;
                            }

                            &[data-value="15"] {
                                background: $cl15;
                            }

                            &[data-value="16"] {
                                background: $cl16;
                            }

                            &[data-value="17"] {
                                background: $cl17;
                            }
                        }
                    }
                }
            }
        }

        p {
            margin: 0;
        }

        .newWindow {
            text-decoration: none;
            font-size: 8px;

            .icon {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &Cont {
        font-weight: $sbold;
        overflow-x: auto;

        table {
            width: 100%;
            margin-bottom: 10px;
            overflow: visible;

            tr {
                td,
                th {
                    position: relative;
                    text-align: center;

                    .opt {
                        &Menu {
                            right: -12px;
                            transform: translateX(0);

                            &:before {
                                left: auto;
                                right: 12px;
                                margin: auto;
                            }

                            &.center-left {
                                top: auto;
                                bottom: 50%;
                                right: calc(100% + 12px);
                                left: auto;
                                transform: translateY(50%);

                                &:before {
                                    top: 0;
                                    bottom: 0;
                                    left: 100%;
                                    right: auto;
                                    transform: rotate(90deg);
                                }
                            }

                            &.center-right {
                                top: auto;
                                bottom: 50%;
                                left: calc(100% + 12px);
                                right: auto;
                                transform: translateY(50%);

                                &:before {
                                    top: 0;
                                    bottom: 0;
                                    right: 100%;
                                    left: auto;
                                    transform: rotate(-90deg);
                                }
                            }

                            &.center-top {
                                transform: translate(calc(50% ), calc(-100% - 3rem));

                                &:before {
                                    top: 100%;
                                    bottom: auto;
                                    transform: rotate(-180deg);
                                }
                            }

                            &.center-bottom {
                                transform: translateX(calc(50% ));

                                &:before {
                                    bottom: auto;
                                    /*top: 100%;*/
                                    /*transform: rotate(-180deg);*/
                                }
                            }

                            &.optMenu-large {
                                width: 130px;
                            }
                        }
                    }

                    img {
                        display: inline-block;
                        width: 14px;
                        height: 11px;
                        padding: 0;
                        margin: 0 3px;
                        vertical-align: middle;
                    }

                    form {
                        margin-bottom: 0;

                        .row {
                            .col {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }

                    .selectize-control.single .selectize-input {
                        background: none;

                        &.dropdown-active {
                            background: $blue;
                        }
                    }

                    .flag {
                        flex: 0 0 30px;
                        max-width: 30px;
                    }

                    .position {
                        display: inline-block;
                        margin: 2px 1px;
                        padding: 1px 5px;
                        color: var(--color-text-secondary);
                        font-size: 6px;
                        border: 2px solid $blue;
                        @include br(50px);

                        &:hover {
                            background: $blue;
                            color: $w;
                        }

                        .icon {
                            padding: 0;
                        }

                        &.down {
                        }

                        &.up {
                            transform: rotate(180deg);
                        }
                    }

                    a {
                        /* white-space: nowrap;
							@include lg {
								white-space: normal;
							} */
                        /* &.btn {
								font-size: 9px;
								padding-left: 15px;padding-right: 15px;
								margin: 0;
							} */
                    }

                    .result {
                        display: inline-block;
                        width: auto;
                        padding: 2px 10px;
                        background: $blue;
                        color: $w;
                        font-size: 10px;
                        white-space: nowrap;
                        @include br(50px);
                    }

                    .type {
                        display: inline-block;
                        padding: 2px 25px;
                        background: darken($g,10%);
                        color: var(--color-text-secondary);
                        font-size: 10px;
                        font-weight: $bold;
                        @include br(50px);
                    }

                    .filter {
                        position: relative;
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        color: var(--color-text-secondary);
                        text-align: center;
                        vertical-align: middle;
                        border: 2px solid $blue;
                        @include br(7px);

                        .icon {
                            padding: 0;
                            margin: 0;
                            font-size: 10px;
                        }

                        &[aria-expanded="true"] {
                            background: $blue;
                            color: $w;
                        }

                        & + .dropdown,
                        & + .dropdown-date-filter {
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            right: auto;
                            width: 240px;
                            height: auto;
                            padding: 17px;
                            margin: 0 auto;
                            overflow: hidden;
                            background: $w;
                            transform: translateX(-50%);
                            will-change: filter;
                            filter: drop-shadow(0 0 10px rgba($b,.3));
                            @include br(17px);
                            z-index: 9999;

                            form {
                                input {
                                    &[type="checkbox"] {
                                        & + label {
                                            padding: 5px;
                                            color: $w;
                                            background: $blue;
                                            @include br(5px);

                                            span {
                                                padding: 0;
                                                color: $w;
                                                font-weight: $medium;

                                                &.day {
                                                    font-size: 15px;
                                                    font-weight: $bold;
                                                }

                                                &.month {
                                                    font-size: 10px;
                                                    font-weight: $bold;
                                                }
                                            }

                                            &:after {
                                                display: none;
                                            }
                                        }

                                        &:checked {
                                            & + label {
                                                background: $bluel;
                                            }
                                        }
                                    }

                                    &[type="submit"] {
                                        border: none;
                                        width: auto;
                                        @include br(50px);
                                    }
                                }

                                .daysSingle {
                                    width: 20%;
                                    max-width: 20%;
                                    flex-basis: 20%;
                                    padding: 1px;
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                    .remove {
                        font-size: 14px;
                    }

                    .buttons {
                        display: inline-block;
                        float: left;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        padding-left: 20px;
                        /* clear: both; */
                        text-align: left;
                        white-space: nowrap;
                        /* @include lg {
								padding-left: 20px;
							} */
                    }

                    .rankingChange {
                        padding: 0 15px;
                        font-weight: $xbold;
                        line-height: 1;

                        .icon {
                            display: inline-block;
                            padding: 0;
                            margin: 0;
                            line-height: 1;
                        }

                        &.inc {
                            .icon {
                                color: $green;
                                transform: rotate(-90deg) translateX(1px);
                            }
                        }

                        &.dec {
                            .icon {
                                color: $red;
                                transform: rotate(90deg) translateX(-1px);
                            }
                        }
                    }

                    .opt {
                        position: relative;

                        a {
                            display: inline-block;

                            &:hover {
                                color: var(--color-text-secondary);
                                opacity: .5;
                            }
                        }

                        &Menu {
                            position: absolute;
                            top: calc(100% + 14px);
                            right: 0px;
                            display: none;
                            float: left;
                            width: 110px;
                            margin: 0 auto;
                            /*padding: 13px;*/
                            list-style: none;
                            background: $w;
                            transform: translateX( calc(50% - 7px) );
                            will-change: filter;
                            filter: drop-shadow(0 0 10px rgba($b,.3));
                            z-index: 9999999;
                            @include br(14px);

                            &.open {
                                display: block;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                bottom: 100%;
                                left: 0;
                                right: 0;
                                display: block;
                                width: 16px;
                                height: 16px;
                                margin: auto;
                                padding: 0;
                                background: none;
                                border-bottom: 14px solid $w;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                            }


                            li {
                                a, button {
                                    display: block;
                                    float: left;
                                    width: 100%;
                                    margin: 0;
                                    margin-bottom: 4px;
                                    padding: 3px;
                                    font-size: 10px;
                                    font-weight: $bold;
                                    white-space: nowrap;
                                    background: $g;
                                    text-decoration: none;

                                    @include br(4rem);

                                    &:hover {
                                        background: $bluel !important;
                                        color: $w;
                                    }
                                }

                                a .btn-success, button.btn-success {
                                    background: $green;
                                }
                            }
                        }
                    }
                }
            }
        }

        &form,
        &.form {
            input {
                padding-top: 3px;
                padding-bottom: 3px;
                color: var(--color-text-secondary);
                font-size: 10px;
                font-weight: $sbold;
                text-align: center;
                border: none;
            }
        }

        form {
            width: 100%;
        }
        /* .box {
				max-width: 100%;
				overflow-y: auto;
			} */
        .lettersList {
            list-style: none;
            padding: 0 10px;
            margin-bottom: 15px;

            li {
                display: inline-block;
                text-transform: uppercase;

                a {
                    display: block;
                    float: left;
                    width: 100%;
                    padding: 0 5px;
                    margin-bottom: 10px;
                    font-size: 12px;
                    font-weight: $bold;
                }
            }
        }

        .playerList {

            @media screen and (max-width: 1024px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            h3, h4 {
                margin-bottom: 10px;
                font-size: 12px;
            }

            a {
                display: inline-block;
                float: left;
                width: 100%;
                clear: both;
                margin: 0;
                padding: 0;
                margin-bottom: 3px;
                font-size: 10px;
            }

            &Single {
                margin-bottom: 20px;

                @include xlg {
                    max-width: 15%;
                    flex-basis: 15%;
                }

                ul {
                    display: block;
                    float: left;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        display: inline-block;
                        float: left;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        a {
                            width: auto;
                            float: left;
                            clear: none;

                            & + a {
                                padding-left: 5px;
                            }

                            img {
                                display: inline-block;
                                width: 14px;
                                height: 11px;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .teamMatches {
            p {
                font-size: 14px;
                margin-bottom: 5px;
            }

            table {
                width: 100%;
                margin-bottom: 0;

                tr {
                    td, th {
                        font-size: 10px;

                        @include lg {
                            padding-left: 3px;
                            padding-right: 3px;
                        }

                        a {
                            text-decoration: none;

                            &.btn {
                                padding: 0px 5px;
                                line-height: 1;

                                &.btn-sm.btn-info {
                                    font-size: 8px;
                                    padding: 0px 3px;
                                    margin: 2px;
                                    line-height: 1.5;
                                }
                            }
                        }

                        p {
                            span {
                                display: inline-block;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }

            .box {
                padding: 10px;
            }
        }

        .teamRequirements {
            display: inline-block;
            padding: 17px 20px;
            margin: 0;
            margin-bottom: 30px;
            font-size: 12px;
            font-weight: $sbold;
            background: #e0e2eb;
            @include br(18px);

            .icon {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin: 0;
                padding: 0;
                margin-right: 5px;
                font-size: 12px;
                color: $w;
                line-height: 12px;
                text-align: center;
                background: $blue;
                @include br(100%);
            }

            p {
                margin-bottom: 10px;
            }

            ul {
                padding-left: 30px;
                margin: 0;
            }
        }

        .teamManagerGrid {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto;
            grid-row-gap: 20px;

            @include lg {
                grid-template-columns: repeat(11, 1fr);
                grid-template-rows: repeat(6, auto);
                grid-row-gap: 20px;
                grid-column-gap: 30px;
            }

            .teamManagerSingle {
                font-size: 10px;

                .bigger {
                    font-size: 12px;
                }

                .flag {
                    display: inline-block;
                    width: 19px;
                    height: 11px;
                    margin: 0;
                    padding: 0;
                    padding-right: 5px;
                    vertical-align: middle;

                    img {
                        display: block;
                        float: left;
                        width: 14px;
                        height: 11px;
                        margin: 0;
                        padding: 0;
                    }
                }

                h4 {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                ul {
                    padding-left: 20px;
                }

                label {
                    padding: 0 10px;
                    margin-bottom: 10px;
                    font-size: 12px;
                    font-weight: $bold;
                }

                input,
                button,
                select,
                textarea {
                    &:not(.btn-dropdown):not(.btn-danger):not(.rin-btn-type-danger) {
                        font-size: 12px;
                        background-color: $tableBg;
                    }

                    &[type="submit"],
                    &[type="button"].btn-search {
                        color: $w;
                        background-color: $green;
                        border-color: $green;

                        &:hover {
                            background-color: $blue;
                            border-color: $blue;
                        }
                    }

                    & + [type="submit"],
                    & + [type="button"].btn-search {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: auto;
                    }

                    &::placeholder {
                        font-style: italic;
                    }

                    &:placeholder-shown {
                        font-style: italic;
                    }
                }

                .overflow-y {
                    overflow-y: auto;
                }

                .box {
                    padding: 20px 10px;
                    margin: 0;

                    @include lg {
                        padding: 20px;
                        overflow: visible;
                    }

                    .selectize-control {
                        float: left;
                    }

                    .selectize-dropdown {
                        max-height: 200px;
                    }

                    .box {
                        background: #ececec;

                        a {
                            text-decoration: none;
                        }

                        p {
                            padding: 0;
                        }

                        :last-child {
                            margin-bottom: 0;
                        }
                    }

                    .teamRequirements {
                        padding: 12px 17px;
                    }
                }

                &.teamManagerName {
                    grid-column: 1;

                    @include lg {
                        grid-column: 1 / 4;
                        grid-row: 1 / 2;
                    }
                }

                &.teamManagerClub {
                    grid-column: 1;

                    @include lg {
                        grid-column: 4 / end;
                        grid-row: 1 / 4;
                    }
                }

                &.teamManagerDivision {
                    grid-column: 1;

                    @include lg {
                        grid-column: 1 / 4;
                        grid-row: 2 / 3;
                    }
                }

                &.teamManagerRegion {
                    grid-column: 1;

                    @include lg {
                        grid-column: 1 / 4;
                        grid-row: 3 / 4;
                    }
                }

                &.teamManagerPlayers {
                    grid-column: 1;

                    @include lg {
                        grid-column: 1 / end;
                        grid-row: 4 / 5;
                    }
                }

                &.teamManagerInfo {
                    grid-column: 1;

                    @include lg {
                        grid-column: 1 / 7;
                        grid-row: 5 / 6;
                    }

                    textarea {
                        background: $gl;
                    }
                }

                .addPlayerSearchInput {
                    .collapse {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        max-width: 80%;
                        margin: auto;
                        background: $tableOnWhiteBg;
                        border: none;
                        overflow: hidden;
                        will-change: filter;
                        filter: drop-shadow(0 0 18px rgba($b,.25));
                        @include br( 0 0 12px 12px);
                        z-index: 99999;

                        .option {
                            padding: 5px 1rem;
                            margin: 0;
                            padding-right: 30px;
                            margin-bottom: 5px;
                            color: var(--color-text-secondary);
                            font-family: $mainFont;
                            font-size: 12px;
                            line-height: 1.125;
                            font-weight: $bold;
                            white-space: nowrap;
                            text-transform: none;
                            box-shadow: none;
                            cursor: pointer;

                            &:last-of-type {
                                margin: 0;
                            }

                            &:hover,
                            &.selected {
                                background: $g;
                                /* background: $blue;
									color: $w; */
                            }

                            .col {
                                margin: 0;
                                padding: 0 5px;

                                .desc {
                                    white-space: normal;
                                }
                            }

                            .flag {
                                width: 20px;
                                max-width: 20px;
                                flex-basis: 20px;
                                padding: 0 3px;
                                text-align: center;

                                .icon {
                                    display: inline-block;
                                    font-size: 14px;
                                }
                            }

                            img {
                                display: inline-block;
                                float: left;
                                width: 14px;
                                height: 11px;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }

                .selectize-control.addplayer.single {
                    .selectize-input {
                        &:after {
                            content: "\e936";
                            font-size: 12px;
                            color: var(--color-text-secondary);
                            transform: rotate(0);
                        }

                        &.dropdown-active {
                            &:after {
                                content: "\e936";
                                font-size: 12px;
                                color: $w;
                                transform: rotate(0);
                            }
                        }
                    }

                    .option {
                        .desc {
                            white-space: normal;
                        }
                    }
                }
            }
        }

        a.info {
            display: inline-block;
            width: 12px;
            height: 12px;
            color: $w;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            background: $blue;
            @include br(100%);
            text-decoration: none;

            &:hover {
                background: $blue2;
            }
        }
    }

    &Notification {
        padding: 20px cs(200, 30);
        margin-bottom: 30px;
        background: $tableOnWhiteBg;
        @include br(22px);

        @include lg {
            padding: 25px 200px;
            @include br(50rem);
        }

        form {
            margin: 0;
            padding: 0;

            h1, h2, h3 {
                margin: 0;
            }

            input {
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 12px;
                color: var(--color-text-secondary);
                text-align: center;

                &[placeholder] {
                    color: var(--color-text-secondary);
                }

                &::-webkit-input-placeholder {
                    color: var(--color-text-secondary);
                }

                &:-moz-placeholder {
                    color: var(--color-text-secondary);
                }

                &::-moz-placeholder {
                    color: var(--color-text-secondary);
                }

                &:-ms-input-placeholder {
                    color: var(--color-text-secondary);
                }

                &[type="text"] {
                    padding-left: 30px;
                    padding-right: 60px;
                }

                &[type="submit"] {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: auto;
                    background: $blue2;
                    color: $w;
                    font-size: 12px;

                    &:hover {
                        background: $blue;
                    }
                }
            }
        }
    }

    &Club {
        .box {
            padding: 15px 20px;
        }
    }

    &Matches {
        padding: 0;

        @include lg {
            padding: 0 15px;
        }
    }
}
// content
