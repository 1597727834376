// content
	.fixedButton {
		position: fixed;bottom: 10%;right: 0;
		z-index: 9;
		@include lg {
			right: 2%;
		}
		a {
			display: block;width: cs(77,33);height: cs(77,33);
			padding: cs(16,4) 0;
			color: $w;font-size: cs(15,9);font-weight: $bold;
			text-align: center;text-decoration: none;
			background: $green;

			transition-property: background, color;
			transition-duration: .3s;
			@include br(100%);
			@include lg {
				width: 77px;height: 77px;
				padding: 16px 0;
				font-size: 15px;
			}
			&:hover {
				background: $blue2;
			}
		}
	}
// content
