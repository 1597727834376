﻿.poster-body {
    padding: 0 !important;  
}

.poster {
    display: block;
    width: 100%;
    max-width: $sm;
    margin: 0 auto;
    padding: 0;

    .banner {
        display: block;
        float: left;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0;
        padding: 30px cs(50,10) 20px;
        background: #3b4b8a;
        color: $w;
        font-size: 11px;

        @include sm {
            padding: 30px 50px 20px;
        }

        .img {
            flex: 0 0 cs(80, 40);
            max-width: cs(80, 40);

            @include sm {
                flex-basis: 80px;
                max-width: 80px;
            }

            img {
                display: block;
                float: left;
                width: 100%;
                margin: 0;
                padding: 0;
                border-radius: 4%;
            }
        }

        .text {
            padding: 0 20px;
            flex: 1 1 auto;
            max-width: calc(100% - 80px);

            h1 {
                font-size: cs(22,16);

                @include sm {
                    font-size: 22px;
                }
            }

            img {
                display: inline-block;
                width: auto;
                height: 10px;
                max-width: 100%;
                padding-right: 3px;
                vertical-align: baseline;
                transform: translateY(1px);
            }

            span + span {
                padding-left: 10px;
            }
        }
    }

    .content {
        display: block;
        float: left;
        width: 100%;
        margin: 0;
        padding: 0 cs(50,10);
        padding-top: 35px;

        @include sm {
            padding: 0 50px;
            padding-top: 35px;
        }

        table {
            font-size: 10px;
            border-collapse: collapse;

            tr {
                border: 4px solid $w;

                td, th {
                    padding: cs(15,7);
                    border: 0;

                    @include sm {
                        padding: 15px;
                    }
                }

                th {
                    background: $tableBg;
                }

                td {
                    background: $gl;
                }

                :first-child {
                    border-radius: 17px 0 0 17px
                }

                :last-child {
                    border-radius: 0 17px 17px 0
                }
            }
        }
    }

    .logos {
        display: block;
        float: left;
        width: 100%;
        clear: both;
        text-align: center;
        padding: 20px 30px;
        margin: 0;
        background: $w;
        overflow: hidden;

        ul {
            display: block;
            float: left;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            margin: 0 auto;
            padding: 0;
            list-style: none inside none;

            li {
                display: inline-block;
                width: 20%;
                vertical-align: middle;
                list-style: none inside none;
                margin: 0;
                padding: 0;

                a {
                    display: block;
                    float: left;
                    width: 100%;
                    margin: 0;
                    padding: 0 cs(20,5);
                    text-align: center;

                    @include sm {
                        padding: 0 20px;
                    }

                    img {
                        display: inline-block;
                        width: auto;
                        max-width: 100%;
                        line-height: 0;
                    }
                }
            }
        }
    }

    .power {
        display: block;
        float: left;
        width: 100%;
        margin: 0;
        padding: 12px cs(50,10);
        background: $blue;
        color: $w;
        font-size: 7px;
        text-align: right;

        @include sm {
            padding: 12px 50px;
        }

        img {
            display: inline-block;
            width: auto;
            height: 9px;
            margin: 0;
            padding: 0;
            vertical-align: bottom;
        }
    }

    .footer {
        display: block;
        float: left;
        width: 100%;
        margin: 0;
        padding: 15px 10px;
        padding-top: 25px;
        font-size: 7px;
        color: $w;
        background: $blue;
        text-align: center;

        a {
            margin: 0;
            color: $w;

            &:hover {
                color: $blue2;
            }
        }
    }
}

.poster-congrats {
    font-size: 11px;

    h1 {
        font-size: cs(22,16);

        @include sm {
            font-size: 22px;
        }
    }
}

.poster-controls {
    display: block;
    width: 100%;
    max-width: $md;
    margin: 0 auto;
    padding: 0;
}
