// content
	.collapse {
		&.animate {
			&.show {
				opacity: 1;
			}
		}
		/* &.animated {
			animation-duration: .2s;
			animation-delay: .1s;
		} */
	}
// content
