// content
.organization {
    padding: 30px 0;

    @include lg {
        padding: 45px 0;
    }

    &Banner {
        padding: 10px 0;
        background: $blue;
        color: $w;

        .wrap {
            overflow: visible;

            .club-list-btn {
                background: #405190;
                border-radius: 50px;
                font-family: Montserrat;
                font-weight: bold;
                font-size: 13px;
                text-align: center;
                color: #FFFFFF;

                &:hover{
                    text-decoration: none;
                }
            }

        }

        .stats {
            margin-bottom: 15px;

            @include lg {
                margin-bottom: 0;
            }

            &Box {
                display: inline-block;
                flex-basis: 100%;
                padding: 10px 20px;
                margin-bottom: 10px;
                font-size: 12px;
                font-weight: $bold;

                @include lg {
                    flex-basis: 0;
                    margin: 0;
                }

                p {
                    display: -webkit-flex;
                    display: -ms-flex;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 0;
                    padding: 0;

                    @include lg {
                        justify-content: space-around;
                    }

                    > span {
                        padding: 0 5px;
                        display: -webkit-flex;
                        display: -ms-flex;
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                }

                .bigger {
                    display: inline-block;
                    float: left;
                    padding: 0;
                    margin: 0;
                    padding-right: 5px;
                    font-size: 28px;
                    font-weight: $xbold;
                    vertical-align: middle;
                }
            }
        }

        .options {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-wrap: nowrap;

            a {
                padding: 7px 20px;
            }

            .btn-org {
                border-radius: 0 50px 50px 0 !important;
                max-width: 25px;
                padding: 0 5px;
                font-size: 9px;
                color: #fff;
                text-align: right;
                background: #46589a;
                padding: 0 10px;
                font-size: 13px;

                &:after {
                    content: none;
                }
            }

            .set-as-home-club-btn {
                .btn-org {
                    background: #00a7e6 !important;
                }

                .btn-primary {
                    background: #00b9ff !important;
                    background-color: #00b9ff !important;

                    &:hover {
                        background: #00b9ff !important;
                        background-color: #00b9ff !important;
                    }
                }
            }

            .embed-event {
                cursor: pointer;
            }

            .no-btn-events {
                pointer-events: none;
            }

/*            .btn {
                border: none;
                @include br(50px 0 0 50px);

                &:hover {
                    color: $w;
                    background: $bluel;
                }
            }*/
        }
    }

    .sidebar {
        .box {
            padding: 20px 15px;
            @include br(28px);

            table {
                margin-bottom: 15px;
            }
        }

        &Rank {
            margin-bottom: 20px;

            @include lg {
                margin-bottom: 44px;
            }

            .org-wrapper {
                margin-bottom: 10px;
            }

            .age-groups-wrapper {
                margin-bottom: 20px;

                .col {
                    padding: 0 5px;
                }
            }

            .no-rankings {
                img {
                    margin-top: 3em;
                    margin-bottom: 3em;
                }

                a {
                    margin-bottom: 3em;
                }

                p {
                    margin-bottom: 3em;
                }
            }
        }

        .member {
            margin-bottom: 20px;

            @include lg {
                margin-bottom: 50px;
            }

            a {
                display: inline-block;
                float: left;
                width: auto;
                margin: 0;
                padding: 0;
            }

            img {
                margin-bottom: 10px;
            }
        }

        .partners {
            img {
                border-radius: 5px;
            }
        }
    }

    .selectize-control.single .selectize-input {
        font-size: 10px;
    }

    .boxTab {
        padding: 20px 0;

        .boxTabCont {
            td {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                }
            }
        }
    }

    .no-latest-videos {
        text-align: center;

        h5 {
            font-size: 14px;
            margin-bottom: 2rem;
        }

        small {
            display: inline-block;
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;
            font-weight: bold;
        }

        @include md {
            text-align: left;

            h5 {
                margin-bottom: 5px;
            }

            small {
                margin-bottom: 2em;
                display: inline-block;
            }

            p {
                font-weight: bold;
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
        }
    }
}
// content
